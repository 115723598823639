import React from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

const ReportReason = ({ cardClass = "h-100", data, allReportsReason }) => {
	const total = data.length;
	//console.log("ReportReason Component ===> ", data, allReportsReason);
	return (
		<Card className={cardClass}>
			<CardBody>
				<CardTitle>
					<p className="font-weight-bold">
						Utilisateurs signalés{" "}
						{/* {allReportsReason[total] ? allReportsReason[total] : 0} */}
					</p>
				</CardTitle>
				{data.map((reason, index) => {
					return (
						<div key={index} className="mb-4">
							<p className="text-muted mb-2">
								{reason.title}
								{":"}
								<span className="float-right text-muted">
									{allReportsReason[index] ? allReportsReason[index] : 0}
									{"/"}
									{+allReportsReason[total] ? allReportsReason[total] : 0}
								</span>
							</p>
							<Progress
								value={
									(allReportsReason[index] / allReportsReason[total]) * 100
								}
							/>
						</div>
					);
				})}
			</CardBody>
		</Card>
	);
};
export default ReportReason;

const exceptions = {
	phoneVerification: {
		"auth/captcha-check-failed":
			"Thrown if the reCAPTCHA response token was invalid, expired, or if this method was called from a non-whitelisted domain.",
		"auth/invalid-phone-number":
			"Le numéro de téléphone utilisé n'est pas valide",
		"auth/missing-phone-number":
			"Le numéro de téléphone utilisé n'est pas valide",
		"auth/quota-exceeded":
			"Thrown if the SMS quota for the Firebase project has been exceeded.",
		"auth/user-disabled":
			"Thrown if the user corresponding to the given phone number has been disabled."
	}
};

export default exceptions;

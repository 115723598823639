import React, { Component, Fragment } from "react";
//UI components
import ThumbListView from "./ThumbListView";
import { Colxx, Separator } from "components/CustomBootstrap";
import BreadcrumbContainer from "components/BreadcrumbContainer";
import { Row, Col } from "reactstrap";

import { connect } from "react-redux";

function collect(props) {
	return { data: props.data };
}
class DetailActivities extends Component {
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		this.state = {
			selectedPageSize: 5,
			pageSizes: [5, 10, 15],
			dropdownSplitOpen: false,
			modalOpen: false,
			totalPage: 1,
			isLoading: false
		};
	}

	componentDidMount() {
		this.setState({
			totalPage: this.props.location.state.detail.length,
			items: this.props.location.state.detail
		});
	}
	categoriesCards = () => {
		//  Sort category names
		const activitiesArray = this.props.location.state.detail;
		return activitiesArray.map(element => (
			<Colxx xxs="12" key={element.activityId} className="mb-3">
				<ThumbListView
					data={element}
					collect={collect}
					history={this.props.history}
				/>
			</Colxx>
		));
	};
	render() {
		return (
			<Fragment>
				<Row>
					<div className="disable-text-selection full-width">
						<Colxx xxs="12">
							<div className="mb-2 d-flex justify-content-between">
								<div>
									<BreadcrumbContainer
										heading={"Liste des activités"}
										match={this.props.match}
									/>
								</div>
							</div>
							<Separator className="mb-5" />
						</Colxx>
					</div>
					{/* <Col sm="3" xs="12">
						<h2>In Progress ...</h2>
					</Col> */}
				</Row>
				<Row>{this.categoriesCards()}</Row>
			</Fragment>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, null)(DetailActivities);

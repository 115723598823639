import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import Button from "reactstrap/lib/Button";
import Imagecard from "./ImageCard";
const SmallCard = props => {
	const {
		title,
		icon,
		activitiesNbr,
		activitiesArray,
		container,
		avatar,
		style,
		detail
	} = props;

	const redirect = () => {
		if (detail)
			props.history.push({
				pathname: props.redirectLink,
				state: { detail: activitiesArray }
			});
	};

	return (
		<div className="icon-cards-row" onClick={redirect}>
			<div className="icon-row-item">
				<Card className="mb-4">
					<div
						className="indigo lighten-1 "
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<Imagecard
							style={style ? style : { width: "100%", height: 250 }}
							alt="indispo"
							userCard={false}
							src={
								avatar
									? process.env.REACT_APP_URL_API +
									  `/storage/${container}/download/` +
									  icon
									: icon
							}
						/>
					</div>
					<CardBody className="text-center">
						<CardTitle className="font-weight-bold font-color-black mb-1">
							{title}
						</CardTitle>
						<CardSubtitle className="font-weight-semibold">
							{activitiesNbr + " Activités créées"}
						</CardSubtitle>
						<Button color="info" onClick={redirect}>
							Voir Plus
						</Button>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export default SmallCard;

import { loginWithEmailAndPassword, logoutUserFromServer } from "api/auth";

import { loginUserSuccess, logoutUser } from "../actions";

export const handleLoginAsync = (
	email,
	password,
	profileType
) => async dispatch => {
	console.log("im here");
	try {
		let login;

		login = await loginWithEmailAndPassword(email, password, profileType);
		console.log("login", login);
		dispatch(loginUserSuccess(login));
		return login;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

/**
 *
 * @param {string} accessToken
 */
export const handleLogoutAsync = (
	accessToken = null,
	profileType
) => async dispatch => {
	try {
		const logout = await logoutUserFromServer(accessToken, profileType);
		dispatch(logoutUser());
		return logout;
	} catch (error) {
		dispatch(logoutUser());
		//TODO
		//Enregistrer l'exception dans la base de donnés
		throw error;
	}
};

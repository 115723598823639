import React, { Component } from "react";
import {
	Row,
	Button,
	ButtonDropdown,
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	CustomInput,
	Collapse
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Colxx, Separator } from "components/CustomBootstrap";
import Breadcrumb from "../navs/Breadcrumb";
class ListPageHeading extends Component {
	constructor(props) {
		super();
		this.state = {
			dropdownSplitOpen: false,
			displayOptionsIsOpen: false
		};
	}

	toggleDisplayOptions = () => {
		this.setState(prevState => ({
			displayOptionsIsOpen: !prevState.displayOptionsIsOpen
		}));
	};
	toggleSplit = () => {
		this.setState(prevState => ({
			dropdownSplitOpen: !prevState.dropdownSplitOpen
		}));
	};

	render() {
		//const { messages } = this.props.intl;
		const {
			displayMode,
			changeDisplayMode,
			handleChangeSelectAll,
			changeOrderBy,
			changePageSize,
			selectedPageSize,
			totalItemCount,
			selectedOrderOption,
			match,
			startIndex,
			endIndex,
			selectedItemsLength,
			itemsLength,
			onSearchKey,
			orderOptions,
			pageSizes,
			toggleModal,
			heading,
			buttonIsHidden,
			editCategory,
			datePicker = false
		} = this.props;

		const { displayOptionsIsOpen, dropdownSplitOpen } = this.state;
		return (
			<Row>
				<Colxx className="align-items-end" xxs="12">
					<div className="mb-4">
						<h1>{heading}</h1>
						<Breadcrumb match={match} />
						<div className="d-flex flex-nowrap flex-row-reverse">
							{buttonIsHidden ? null : (
								<ButtonDropdown
									isOpen={dropdownSplitOpen}
									toggle={this.toggleSplit}
								>
									<DropdownToggle
										caret
										color="info"
										className="dropdown-toggle-split btn-lg"
									/>
									<DropdownMenu right>
										<DropdownItem onClick={editCategory}>
											{"Modifier"}
										</DropdownItem>
									</DropdownMenu>
								</ButtonDropdown>
							)}
							{buttonIsHidden ? null : (
								<Button
									color="info"
									//outline
									size="lg"
									className="top-right-button"
									onClick={() => toggleModal()}
									style={{ width: 150 }}
								>
									{"AJOUTER"}
								</Button>
							)}
						</div>
					</div>

					<div className="mb-2">
						<Button
							color="empty"
							className="pt-0 pl-0 d-inline-block d-md-none"
							onClick={this.toggleDisplayOptions}
						>
							{" Option d'affichage "}
							<i className="simple-icon-arrow-down align-middle" />
						</Button>
						<Collapse
							isOpen={displayOptionsIsOpen}
							className="d-md-block"
							id="displayOptions"
						>
							<div className="d-block d-md-inline-block pt-1">
								<UncontrolledDropdown className="mr-4 float-md-left btn-group mb-1">
									<DropdownToggle outline caret color="outline-info" size="xs">
										{selectedOrderOption.label}
									</DropdownToggle>
									<DropdownMenu>
										{orderOptions.map((order, index) => {
											return (
												<div key={"DropdownItem" + index}>
													<DropdownItem
														key={"DropdownItem" + index}
														onClick={() => changeOrderBy(order.column)}
													>
														{order.label}
													</DropdownItem>
													<Separator />
												</div>
											);
										})}
									</DropdownMenu>
								</UncontrolledDropdown>
								<div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
									<input
										type="text"
										name="keyword"
										id="search"
										placeholder={"Rechercher"}
										onKeyPress={e => onSearchKey(e)}
									/>
								</div>
								{datePicker ? this.props.renderDatePicker : null}
							</div>
						</Collapse>
					</div>

					<Separator className="mb-5" />
				</Colxx>
			</Row>
		);
	}
}

export default ListPageHeading;
// injectIntl(ListPageHeading);

import { urlApi } from "constants/defaultValues";

export const getUserBlocked = async ({
	accessToken,
	profileType = "blockedUser"
}) => {
	try {
		const response = await fetch(
			urlApi +
				`/${profileType}/?filter[include]=fkBlockeduserUsertoblockrel&filter[include]=fkBlockeduserUserrel`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

import React, { Component } from "react";
import { Card } from "reactstrap";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import ThumbnailLetters from "components/ThumbnailLetters";
//logo-whitee.png
import logo from "assets/img/user-13-icon-256.png";
class ThumbListView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			//src: props.src,
			errored: false
		};
	}
	onError = () => {
		if (!this.state.errored) {
			this.setState({
				errored: true
			});
		}
	};
	renderImageCard = () => {
		const { data, onClickDetail } = this.props;
		const { errored } = this.state;
		console.log("errored", errored);
		if (data.providerAvatarURL || data.Avatar) {
			if (errored === false) {
				return (
					<img
						alt={"erreur se produit"}
						src={
							data.Avatar
								? process.env.REACT_APP_URL_API +
								  "/storage/profile/download/" +
								  data.Avatar.name
								: data.providerAvatarURL
						}
						onClick={onClickDetail}
						className="list-thumbnail responsive border-0 card-img-left"
						style={{
							height: 100,
							width: 150
						}}
						onError={this.onError}
					/>
				);
			} else {
				return (
					<ThumbnailLetters
						small
						rounded={false}
						text={
							(data.firstName != null ? data.firstName : "") +
							" " +
							(data.lastName != null ? data.lastName : "")
						}
						//className="m-4"
						color="info"
					/>
				);
			}
		} else {
			return (
				<ThumbnailLetters
					small
					rounded={false}
					text={
						(data.firstName != null ? data.firstName : "") +
						" " +
						(data.lastName != null ? data.lastName : "")
					}
					className="m-4"
					color="info"
				/>
			);
		}
	};
	componentDidMount() {
		this.setState({
			errored: false
		});
	}
	render() {
		const { data, isSelect, collect, onClickDetail } = this.props;
		//const errored = this.state;
		return (
			<ContextMenuTrigger id="menu_id" data={data.userId} collect={collect}>
				<Card
					className={classnames("d-flex flex-row", {
						active: isSelect
					})}
					onClick={event => onClickDetail(event, data.userId)}
				>
					<div className="pl-2 d-flex flex-grow-1 min-width-zero">
						<div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
							{this.renderImageCard()}
							<p className="mb-1 text-muted text-semibold w-15 w-sm-100 ">
								{data.firstName} {data.lastName}
							</p>

							<p className="mb-1 text-muted text-semibold w-15 w-sm-100">
								{data.Followers.length + " Abonnées"}
							</p>
							<p className="mb-1 text-muted text-semibold w-15 w-sm-100">
								{data.Activities.length + " Activités Créée"}
							</p>
							<p className="mb-1 text-muted text-semibold w-15 w-sm-100">
								{"Participé a " +
									data.ParticipatedActivities.length +
									" activités"}
							</p>
							<p className="mb-1 text-muted text-semibold w-15 w-sm-100">
								{data.tokensCount + " Jetons"}
							</p>
						</div>
						<div className="custom-control custom-checkbox color-info pl-1 align-self-center pr-4"></div>
					</div>
				</Card>
			</ContextMenuTrigger>
		);
	}
}

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ThumbListView);

import { urlApi } from "constants/defaultValues";
export const countActivitiesWithDate = async (accessToken, quaryOperation) => {
	try {
		const response = await fetch(urlApi + `/activity/count${quaryOperation}`, {
			method: "GET",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

export const getActivitiesWithAddress = async ({ accessToken }) => {
	try {
		const response = await fetch(
			urlApi +
				`/Addresses/?filter={
				"include":{ "relation":"Activities"}
			}`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

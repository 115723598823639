import React, { Component } from "react";
import logo from "assets/img/user-13-icon-256.png";
// import ThumbnailLetters from "components/ThumbnailLetters";
class Imagecard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			src: props.src,
			errored: false
		};
	}

	onError = () => {
		if (!this.state.errored) {
			this.setState({
				src: logo,
				errored: true
			});
		}
	};

	render() {
		const { errored } = this.state;
		const { src: _1, fallbackSrc: _2, userCard, ...props } = this.props;
		let source = this.props.src;
		if (userCard && errored) {
			source = logo;
		}
		return <img src={source} onError={this.onError} {...props} />;
	}
}
export default Imagecard;

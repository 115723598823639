import {
	UPDATE_TOKEN_SUCCESS,
	UPDATE_TOKEN_FAILURE
} from "constants/actionTypes";
const INIT_STATE = {
	profile: null,
	loading: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case UPDATE_TOKEN_SUCCESS:
			let { profile } = action;
			return { ...state, profile };

		case UPDATE_TOKEN_FAILURE:
			if (action.profile) {
				return {
					...state,
					profile: { ...state.profile, profile: action.profile }
				};
			}
			return { ...state };

		default:
			return { ...state };
	}
};

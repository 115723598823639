import React from "react";
import {
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Label,
	InputGroup
} from "reactstrap";
//Form validation
import {
	AvForm,
	AvField,
	AvGroup,
	AvInput,
	AvFeedback,
	AvRadioGroup,
	AvRadio
} from "availity-reactstrap-validation";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
// If you want to use the provided css
import "react-google-places-autocomplete/dist/assets/index.css";

const EditActivityModal = ({
	modalOpen,
	toggleModal,
	newActivityAssress,
	newActivityDate,
	handleSubmit,
	setActivityDate,
	setActivityAddress,
	onCancelDate,
	onCancelAddress
}) => {
	const renderFormComponent = () => {
		return (
			<AvForm
				className="av-tooltip tooltip-label-right"
				//model={}
				onSubmit={handleSubmit}
			>
				<AvGroup>
					<Label>Date</Label>
					<AvInput
						name="date"
						onChange={setActivityDate}
						value={newActivityDate}
						onCancel={onCancelDate}
						clearIcon={true}
					/>
				</AvGroup>
				<AvGroup>
					<Label className="mt-4">Adresse</Label>
					{/* <AvInput
						type="search"
						name="address"
						onChange={setActivityAddress}
						value={newActivityAssress}
						onCancel={onCancelAddress}
					/> */}
					<GooglePlacesAutocomplete
						renderSuggestions={(active, suggestions, setActivityAddress) => (
							<div className="suggestions-container">
								{suggestions.map(suggestion => (
									<div
										className="suggestion"
										onClick={suggestion => setActivityAddress(suggestion)}
									>
										{suggestion.description}
									</div>
								))}
							</div>
						)}
					/>
				</AvGroup>
				<Button color="primary" outline>
					Modifier
				</Button>{" "}
			</AvForm>
		);
	};
	return (
		<Modal
			isOpen={modalOpen}
			toggle={toggleModal}
			wrapClassName="modal-right"
			backdrop="static"
		>
			<ModalHeader toggle={toggleModal}>In progress ....</ModalHeader>

			<ModalBody>{renderFormComponent()}</ModalBody>
		</Modal>
	);
};

export default EditActivityModal;

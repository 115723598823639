import React from "react";
import { Route, Switch } from "react-router-dom";
import All from "./activitiesListe/All";

function Activities({ match }) {
	return (
		<>
			<Switch>
				<Route path={`${match.url}`} component={All} />
			</Switch>
		</>
	);
}
export default Activities;

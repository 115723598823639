import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import All from "./All";

const Utilisateurs = props => {
	const { match, currentUser } = props;
	return (
		<Switch>
			<Route
				path={`${match.url}`}
				render={() => <All currentUser={currentUser} {...props} />}
			/>
			<Redirect to="/error" />
		</Switch>
	);
};

const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, null)(Utilisateurs);

import { chartTooltip } from "../../util/Utils";

export const areaChartOptions = {
	legend: {
		display: false
	},
	responsive: true,
	maintainAspectRatio: false,
	tooltips: chartTooltip,
	scales: {
		yAxes: [
			{
				gridLines: {
					display: true,
					lineWidth: 1,
					color: "rgba(0,0,0,0.1)",
					drawBorder: true
				},
				ticks: {
					beginAtZero: true,
					stepSize: 1,
					min: 0,
					max: 65,
					padding: 10
				}
			}
		],
		xAxes: [
			{
				gridLines: {
					display: false
				}
			}
		]
	}
};

import React, { Component, Fragment } from "react";
import { Row, Button, Popover, PopoverBody } from "reactstrap";
//UI
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
//Custome UI
import ThumbListView from "./ThumbListView";
import ListPageHeading from "components/ListPageHeading";
import Pagination from "components/Pagination";
import DetailUserModal from "components/DetailUserModal";
import { Colxx } from "components/CustomBootstrap";
//Date
import moment from "moment";
//Api
import { getAllProfiles /*, getAllProfilesWithoutlimit*/ } from "api/profile";
import { getFilterProfiles, getAllUsers } from "api/user";
//Redux
import { connect } from "react-redux";
import { handleUpdateTokenAsync } from "redux/actions";
//Excel
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function collect(props) {
	return { data: props.data };
}

class UsersList extends Component {
	constructor(props) {
		super(props);
		this.updateCurrentUserToken = this.updateCurrentUserToken.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this); //Update -> user token
		this.handleChangeStart = this.handleChangeStart.bind(this); //Filter -> strat date
		this.handleChangeEnd = this.handleChangeEnd.bind(this); //Filter -> end date
		this.renderDatePicker = this.renderDatePicker.bind(this); //show DatePicker component
		this.handleSubmitDateFilter = this.handleSubmitDateFilter.bind(this); //filter users with a date range
		this.showDetailUser = this.showDetailUser.bind(this); // user detail
		this.toggleModal = this.toggleModal.bind(this); //open modal -> user detail

		const orderOptions = [
			{ column: "firstName", label: "Nom", value: "Desc" },
			{ column: "createdAt", label: "Date de création", value: "DESC" },
			{ column: "google", label: "Google", value: 2 },
			{ column: "fb", label: "Facebook", value: 1 },
			{ column: "ywg", label: "Compte Yepwego", value: null }
		];
		this.state = {
			isLoading: true,
			utilisateurs: [],
			selectedOrderOption: orderOptions[0],
			dropdownSplitOpen: false,
			modalOpen: false,
			currentPage: 1, //current page , default -> 1
			totalItemCount: 1,
			totalPage: 100,
			search: "", //search word
			selectedItems: [], //select items
			lastChecked: null,
			displayMode: "thumblist",
			selectedPageSize: 5,
			orderOptions: orderOptions,
			userAvatarId: [],
			userSelected: null,
			detailUser: {}, //object of user
			tokensToAdd: 0, //token to update
			isOpenCollapse: false, //
			profiles: [],
			isLoadingTokenButton: "default", //token buttons state
			startDateRange: null, //Filter -> strat date
			endDateRange: null, //Filter -> end date
			isFilterUsersButton: false, //
			openPopoverButton: false //open popover -> range date
		};
	}
	/**
	 * component mount
	 */
	componentDidMount() {
		this.dataListRender(); //-> get user
		this.getAllProfilesWithoutlimitt(); //Get all user list --> Excel file
	}
	/**
	 * unmount component
	 */
	componentWillUnmount() {}
	/**
	 * Mhow detail user modal
	 */
	toggleModal() {
		this.setState({
			modalOpen: !this.state.modalOpen
		});
	}

	/**
	 * handle update CurrentUser Token
	 * @param {event} event
	 * @param {event} errors
	 * @param {event} values
	 */
	handleSubmit(event, errors, values) {
		this.setState({ isLoadingTokenButton: "processing" });
		values["minPropNumberProp"] = "";
		if (errors.length === 0) {
			setTimeout(() => {
				this.updateCurrentUserToken();
			}, 3000);
		} else if (errors.length !== 0) {
			this.setState({ isLoadingTokenButton: "fail" });
		}
	}
	/**
	 * Paginate responses
	 * @param {number} page page number
	 */
	onChangePage = page => {
		const { startDateRange, endDateRange, selectedOrderOption } = this.state;
		this.setState(
			{
				currentPage: page,
				isLoading: true
			},
			() => {
				if (
					(startDateRange === null &&
						endDateRange === null &&
						selectedOrderOption.column === "google") ||
					selectedOrderOption.column === "fb" ||
					selectedOrderOption.column === "ywg"
				) {
					let condition = `,"provider":${selectedOrderOption.value}`;
					this.filterataListRender(condition);
				} else if (selectedOrderOption.column === "createdAt") {
					this.filterataListRender("");
				} else if (startDateRange !== null && endDateRange !== null) {
					let condition = "";
					if (
						selectedOrderOption.column === "google" ||
						selectedOrderOption.column === "fb" ||
						selectedOrderOption.column === "ywg"
					) {
						condition = `,"provider":${selectedOrderOption.value}`;
					}
					let socialMediaCond = condition !== "" ? condition : "";

					let filterDate = `,"and":  [  {"createdAt": {"gt": "${moment(
						startDateRange
					).add(-1, "days")}"}},  {"createdAt": {"lt": "${moment(
						endDateRange
					).add(1, "days")}"}} ]`;
					let filterDateAndSocialMedia = `${filterDate}${socialMediaCond}`;
					condition =
						socialMediaCond !== "" ? filterDateAndSocialMedia : filterDate;
					this.filterataListRender(condition);
				} else {
					this.dataListRender();
				}
			}
		);
	};
	/**
	 * Specifies how to sort the results
	 */
	changeOrderBy = column => {
		this.setState(
			{
				startDateRange: null,
				endDateRange: null,
				isLoading: true,
				selectedOrderOption: this.state.orderOptions.find(
					x => x.column === column
				)
			},
			() => {
				if (column === "firstName") {
					this.dataListRender();
					this.getAllProfilesWithoutlimitt();
				} else if (column === "createdAt") {
					this.filterataListRender("");
					this.getAllProfilesWithoutlimitt();
				} else if (column === "google") {
					let cond = `,"provider":  2`;
					this.filterataListRender(cond); // get all user with limit and skip (5) for card user
					this.getAllProfilesWithoutlimitt(); //get all user without limit and skip for excel file
				} else if (column === "fb") {
					let cond = `,"provider":  1`;
					this.filterataListRender(cond); // get all user with limit and skip (5) for card user
					this.getAllProfilesWithoutlimitt(); //get all user without limit and skip for excel file
				} else if (column === "ywg") {
					let condition = `,"provider":null`;
					this.filterataListRender(condition); // get all user with limit and skip (5) for card user
					this.getAllProfilesWithoutlimitt(); //get all user without limit and skip for excel file
				}
			}
		);
	};
	/**
	 * filter the data with word enter with a user
	 * @param {event} e
	 */
	onSearchKey = e => {
		if (e.key === "Enter") {
			this.setState(
				{
					search: e.target.value
					//.toLowerCase()
				},
				() => this.dataListRender()
			);
		}
	};
	/**
	 * Check user card
	 * @param {event} event
	 * @param {string} id
	 */
	onCheckItem = (event, id) => {
		if (
			event.target.tagName === "A" ||
			(event.target.parentElement && event.target.parentElement.tagName === "A")
		) {
			return true;
		}
		if (this.state.lastChecked === null) {
			this.setState({
				lastChecked: id
			});
		}
		let selectedItems = this.state.selectedItems;
		/* Select already selected item */
		if (selectedItems.includes(id)) {
			this.setState({
				selectedItems: [],
				categoryToEdit: null,
				lastChecked: null
			});
		} else {
			/* Select new item */
			selectedItems = [];
			selectedItems.push(id);
			this.setState({
				selectedItems
			});
		}

		if (event.shiftKey) {
			console.log("even.shiftKey");
			var items = this.state.items;
			var start = this.getIndex(id, items, "id");
			var end = this.getIndex(this.state.lastChecked, items, "id");
			items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
			selectedItems.push(
				...items.map(item => {
					return item.categoryId;
				})
			);
			selectedItems = Array.from(new Set(selectedItems));
			this.setState({
				selectedItems
			});
		}
		document.activeElement.blur();
	};
	/**
	 * handle change select all item
	 * @param {bool} isToggle
	 */
	handleChangeSelectAll = isToggle => {
		if (this.state.selectedItems.length >= this.state.items.length) {
			if (isToggle) {
				this.setState({
					selectedItems: []
				});
			}
		} else {
			this.setState({
				selectedItems: this.state.items.map(x => x.categoryId)
			});
		}
		document.activeElement.blur();
		return false;
	};
	/**
	 * Get all profile
	 * @param {string} 	accessToken user token
	 * @param {number} currentPage paginate responses
	 * @param {number} records_per_page number of records per page
	 * @param {string} search key for search
	 * @param {string} orderOption specifies how to sort the results
	 */
	async dataListRender() {
		const {
			selectedPageSize,
			currentPage,
			selectedOrderOption,
			search
		} = this.state;
		const { id: accessToken } = this.props.currentUser;
		const records_per_page = selectedPageSize;

		let orderOption =
			selectedOrderOption.column === "firstName"
				? `,"order":"${selectedOrderOption.column}%20Asc"`
				: "";

		try {
			getAllProfiles({
				accessToken,
				currentPage,
				records_per_page,
				search,
				orderOption
			})
				.then(profiles => {
					console.log("profiles===> ", profiles);
					this.setState({
						userAvatarId: profiles,
						items: profiles,
						utilisateurs: profiles,
						isLoading: false
					});
				})
				.catch(error => console.log("error=>", error));
		} catch (err) {
			throw err;
		}
	}
	/**
	 * get users with condition
	 * @param {string} condition c
	 */

	async filterataListRender(cond) {
		const {
			selectedPageSize,
			currentPage,
			selectedOrderOption,
			search
		} = this.state;
		let condition = cond;
		const { id: accessToken } = this.props.currentUser;
		const records_per_page = selectedPageSize;
		const orderOption =
			selectedOrderOption.column === "createdAt"
				? `,"order":"createdAt%20DESC"`
				: "";

		try {
			let result = await getFilterProfiles({
				accessToken,
				currentPage,
				records_per_page,
				search,
				orderOption,
				condition
			});

			console.log("result --> ", result);
			let profiles = result.map(user => {
				return {
					User: {
						createdAt: user.createdAt,
						email: user.email,
						emailVerified: user.emailVerified,
						fcmToken: user.fcmToken,
						id: user.id,
						lastLogin: user.lastLogin,
						provider: user.provider,
						realm: user.realm,
						username: user.username
					},
					avatarId: user.Profile.avatarId,
					birthdate: user.Profile.birthdate,
					countryCode: user.Profile.countryCode,
					coverId: user.Profile.coverId,
					description: user.Profile.description,
					firstName: user.Profile.firstName,
					isMale: user.Profile.isMale,
					lastName: user.Profile.lastName,
					lastNotificationsSeenDate: user.Profile.lastNotificationsSeenDate,
					providerAvatarURL: user.Profile.providerAvatarURL,
					pseudo: user.Profile.pseudo,
					sponsoredBy: user.Profile.sponsoredBy,
					sponsorshipCode: user.Profile.sponsorshipCode,
					tokensCount: user.Profile.tokensCount,
					userId: user.Profile.userId,
					Activities: user.Profile.Activities,
					Avatar: user.Profile.Avatar,
					BlockedProfile: user.Profile.BlockedProfile,
					Categories: user.Profile.Categories,
					FollowRequest: user.Profile.FollowRequest,
					Followers: user.Profile.Followers ? user.Profile.Followers : [],
					ParticipatedActivities: user.Profile.ParticipatedActivities
						? user.Profile.ParticipatedActivities
						: [],
					ReceivedFollowRequest: user.Profile.ReceivedFollowRequest
						? user.Profile.ReceivedFollowRequest
						: []
				};
			});
			this.setState({
				userAvatarId: profiles,
				items: profiles,
				utilisateurs: profiles,
				isLoading: false
			});
		} catch (err) {
			throw err;
		}
	}
	/**
	 * Get all profiles for exporting in excel file
	 */

	async getAllProfilesWithoutlimitt() {
		const { id: accessToken } = this.props.currentUser;
		const { selectedOrderOption, startDateRange, endDateRange } = this.state;
		try {
			let cond = "";
			let orderOption = "";

			if (
				selectedOrderOption.column === "google" ||
				selectedOrderOption.column === "fb" ||
				selectedOrderOption.column === "ywg"
			) {
				cond = `,"provider":${selectedOrderOption.value}`;
			} else if (selectedOrderOption.column === "createdAt") {
				orderOption = `,"order":"createdAt%20DESC"`;
			}
			if (startDateRange !== null && endDateRange !== null) {
				let socialMediaCond = cond !== "" ? cond : "";
				let filterDate = `,"and":  [  {"createdAt": {"gt": "${moment(
					startDateRange
				).add(-1, "days")}"}},  {"createdAt": {"lt": "${moment(
					endDateRange
				).add(1, "days")}"}} ]`;
				let filterDateAndSocialMedia = `${filterDate}${socialMediaCond}`;
				cond = socialMediaCond !== "" ? filterDateAndSocialMedia : filterDate;
			}
			var profiles = await getAllUsers({ accessToken, cond, orderOption });
			this.setState({
				profiles: profiles, //total users
				totalPage: Math.trunc(profiles.length / this.state.selectedPageSize), //Total number of pages
				isLoading: false
			});
			console.log("excel user ", selectedOrderOption.column, "-> ", profiles);
		} catch (err) {
			throw err;
		}
	}
	/**
	 * Modify the input field
	 * @param {event} event
	 */
	setUserToken = event => {
		this.setState({
			tokensToAdd: event.target.value
		});
	};
	/*
	return new Promise((success, fail) => {
		setTimeout(() => {
		  success("Everything went right!");
		}, 2000);
	  });
	  */
	/**
	 * Update number of user tokens for specoific user
	 */
	async updateCurrentUserToken() {
		let user = this.state.detailUser;
		let tokensToAdd = this.state.tokensToAdd;
		const { id: accessToken } = this.props.currentUser;
		try {
			if (tokensToAdd > 0) {
				let userdata = {
					userId: String(user.userId),
					token: Number(user.tokensCount) + Number(tokensToAdd)
				};
				let response = await this.props.handleUpdateTokenAsync(
					accessToken,
					userdata
				);
				console.log("response ", response);
				user["tokensCount"] = userdata.token;
				this.setState({
					isLoadingTokenButton: "success",
					detailUser: user,
					tokensToAdd: 0
				});
				setTimeout(() => {
					this.setState({
						isLoadingTokenButton: "default"
					});
				}, 2000);

				this.dataListRender();
			} else {
				this.setState({
					isLoadingTokenButton: 1,
					tokensToAdd: 0
				});
				setTimeout(() => {
					this.setState({
						isLoadingTokenButton: "default"
					});
				}, 2000);
			}
		} catch (error) {
			throw error;
		}
	}

	/**
	 * Show user detail
	 * @param {event} event
	 * @param {sring} id user id
	 */
	showDetailUser = (event, id) => {
		let usersArray = this.state.userAvatarId;
		let detailUser = usersArray.find(element => element.userId === id);

		const userOject = {
			Activities: detailUser.Activities.length,
			//Categories: detailUser.Categories.legth,
			Followers: detailUser.Followers.length,
			ParticipatedActivities: detailUser.ParticipatedActivities.length,
			BlockedProfile: detailUser.BlockedProfile.length,
			Avatar: detailUser.Avatar ? detailUser.Avatar.name : null,
			email:
				detailUser.User.email.indexOf("@fb") > -1 ||
				detailUser.User.email.indexOf("@google") > -1
					? detailUser.User.email.indexOf("@fb") > -1
						? "Facebook"
						: "Google"
					: detailUser.User.email,
			createdAt: detailUser.User.createdAt,
			lastLogin: detailUser.User.lastLogin,
			birthdate: detailUser.birthdate,
			countryCode: detailUser.countryCode,
			description: detailUser.description,
			firstName: detailUser.firstName,
			isMale: detailUser.isMale,
			lastName: detailUser.lastName,
			lastNotificationsSeenDate: detailUser.lastNotificationsSeenDate,
			providerAvatarURL: detailUser.providerAvatarURL,
			pseudo: detailUser.pseudo,
			sponsoredBy: detailUser.sponsoredBy,
			sponsorshipCode: detailUser.sponsorshipCode,
			tokensCount: detailUser.tokensCount,
			userId: detailUser.userId
		};

		this.setState({ detailUser: userOject });
		this.toggleModal();
	};
	/**
	 * Return users card
	 * @param {JSON} utilisateursObject array of users profiles
	 */
	userCards = utilisateursObject => {
		const activitiesArray = utilisateursObject;
		return activitiesArray.map((element, index) => (
			<Colxx xxs="12" key={`ThumbListView_${index}`} className="mb-3">
				<ThumbListView
					data={element}
					isSelect={this.state.selectedItems.includes(element.coverId)}
					collect={collect}
					onClickDetail={this.showDetailUser}
					onCheckItem={this.onCheckItem}
				/>
			</Colxx>
		));
	};
	/**Change date -> start date */
	handleChangeStart = date => {
		this.setState({
			startDateRange: date
		});
	};
	/**Change date -> end date */
	handleChangeEnd = date => {
		this.setState({
			endDateRange: date
		});
	};

	/**
	 * render date picker -> filter user with created date
	 */
	handleSubmitDateFilter = () => {
		console.log("handleSubmitDateFilter");
		let { startDateRange, endDateRange, selectedOrderOption } = this.state;
		if (startDateRange === null || endDateRange === null) {
			this.setState({
				isFilterUsersButton: false,
				openPopoverButton: true
			});
		} else {
			let socialMediaCond = "";
			if (
				selectedOrderOption.column === "fb" ||
				selectedOrderOption.column === "google" ||
				selectedOrderOption.column === "ywg"
			) {
				socialMediaCond = `,"provider":${selectedOrderOption.value}`;
			}
			let condition = `,"and":[{"createdAt": {"gt": "${moment(
				startDateRange
			).add(-1, "days")}"}},  {"createdAt": {"lt": "${moment(endDateRange).add(
				1,
				"days"
			)}"}}]`;
			let cond = `${condition}${socialMediaCond}`;

			this.setState({ isLoading: false });
			/* Filter users with inscription date range */
			this.filterataListRender(cond);
			this.getAllProfilesWithoutlimitt();
		}
		setTimeout(() => {
			this.setState({
				openPopoverButton: false /*close popover button*/
			});
		}, 1000);
	};

	/**
	 * Render date picker component
	 */
	renderDatePicker = a => {
		return (
			<Row className="ml-0">
				<div className="mb-5 d-inline-block  float-md-left mr-1 mb-1 align-top">
					{/* <Label>De</Label> */}
				</div>
				<div className="mb-5 d-inline-block  float-md-left mr-1 mb-1 align-top">
					<DatePicker
						selected={this.state.startDateRange}
						selectsStart
						startDate={this.state.startDateRange}
						endDate={this.state.endDateRange}
						onChange={this.handleChangeStart}
						placeholderText="Date début"
						maxDate={
							this.state.endDateRange !== null
								? this.state.endDateRange
								: new Date()
						}
					/>
				</div>
				<div className=" d-inline-block  float-md-left mr-1 mb-1 align-top">
					{/* <Label>À</Label> */}
				</div>
				<div className=" float-md-left mr-1 mb-1 align-top">
					<DatePicker
						selected={this.state.endDateRange}
						selectsEnd
						startDate={this.state.startDateRange}
						endDate={this.state.endDateRange}
						onChange={this.handleChangeEnd}
						placeholderText="date fin"
						maxDate={new Date()}
					/>
				</div>
				<div className=" float-md-left mr-1 mb-1 align-top">
					<Button
						id="filterUsersButton"
						// inline
						onClick={this.handleSubmitDateFilter}
					>
						<span className="label">Confirmer</span>
						<Popover
							placement="top"
							isOpen={this.state.openPopoverButton}
							target={"filterUsersButton"}
						>
							<PopoverBody>
								{this.state.isFilterUsersButton ? " succés" : "Erreur"}
							</PopoverBody>
						</Popover>
					</Button>
				</div>
				<ExcelFile
					element={
						<Button className="w-35" color="secondary" outline>
							Exporter en Excel
						</Button>
					}
				>
					<ExcelSheet data={a} name="userExcel">
						<ExcelColumn label="FirstName" value="firstName" />
						<ExcelColumn label="Last Name" value="lastName" />
						{/* <ExcelColumn label="E-mail" value="email" /> */}
						<ExcelColumn
							label="Email"
							value={col =>
								col.email.indexOf("@fb") > -1 ||
								col.email.indexOf("@google") > -1
									? col.email.indexOf("@fb") > -1
										? "Facebook"
										: "Google"
									: col.email
							}
						/>
					</ExcelSheet>
				</ExcelFile>
			</Row>
		);
	};
	/**
	 *
	 */
	render() {
		const {
			currentPage,
			items,
			displayMode,
			selectedPageSize,
			totalItemCount,
			selectedOrderOption,
			selectedItems,
			orderOptions,
			pageSizes,
			modalOpen,
			isLoading,
			userAvatarId,
			detailUser,
			profiles,
			isOpenCollapse,
			isLoadingTokenButton,
			tokensToAdd
		} = this.state;

		var a = profiles.map(element => {
			return {
				firstName: element.Profile.firstName,
				lastName: element.Profile.lastName,
				email: element.email
			};
		});
		const { match } = this.props;
		const startIndex = (currentPage - 1) * selectedPageSize;
		const endIndex = currentPage * selectedPageSize;
		return (
			<Fragment>
				<div className="disable-text-selection">
					<ListPageHeading
						heading="Liste des utilisateurs"
						displayMode={displayMode}
						// changeDisplayMode={this.changeDisplayMode}
						handleChangeSelectAll={this.handleChangeSelectAll}
						changeOrderBy={this.changeOrderBy}
						changePageSize={this.changePageSize}
						selectedPageSize={selectedPageSize}
						totalItemCount={totalItemCount}
						selectedOrderOption={selectedOrderOption}
						match={match}
						startIndex={startIndex}
						endIndex={endIndex}
						selectedItemsLength={selectedItems ? selectedItems.length : 0}
						itemsLength={items ? items.length : 0}
						onSearchKey={this.onSearchKey}
						orderOptions={orderOptions}
						pageSizes={pageSizes}
						toggleModal={this.toggleModal}
						buttonIsHidden={true}
						datePicker={true}
						startDateRange={this.state.startDateRange}
						endDateRange={this.state.endDateRange}
						handleChangeStart={this.handleChangeStart}
						handleChangeEnd={this.handleChangeEnd}
					/>
					{this.renderDatePicker(a)}
					<DetailUserModal
						modalOpen={modalOpen}
						toggleModal={this.toggleModal}
						user={detailUser}
						isOpenCollapse={isOpenCollapse}
						handleSubmit={this.handleSubmit}
						setUserToken={this.setUserToken}
						isLoadingTokenButton={isLoadingTokenButton}
						tokensToAdd={tokensToAdd}
					/>
				</div>
				{isLoading ? (
					<div className="loading" />
				) : (
					<Row>
						{this.userCards(userAvatarId)}
						<Pagination
							currentPage={this.state.currentPage}
							totalPage={this.state.totalPage}
							onChangePage={i => this.onChangePage(i)}
						/>
					</Row>
				)}
			</Fragment>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, { handleUpdateTokenAsync })(UsersList);

import {
	REGISTER_CATEGORY_SUCCESS,
	UPDATE_CATEGORY_SUCCESS
} from "constants/actionTypes";
const INIT_STATE = {
	category: null,
	loading: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REGISTER_CATEGORY_SUCCESS:
			let { category } = action;
			return { ...state, category };

		case UPDATE_CATEGORY_SUCCESS:
			if (action.category) {
				return {
					...state,
					category: { ...state.category, category: action.category }
				};
			}
			return { ...state };

		default:
			return { ...state };
	}
};

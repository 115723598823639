import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

//Redux
import { connect } from "react-redux";
import TopNav from "admin/containers/TopNav";
import Sidebar from "admin/containers/Sidebar";
import Dashboards from "admin/routes/dashboards/UI";
import PaymentUI from "admin/routes/payment";
import ContentManagement from "./content-management";
import Activities from "./activities";
import Utilisateurs from "./utilisateurs";
import DetailActivities from "./details/DetailActivities";
import DetailActivityComponent from "./details/DetailActivityComponent";
class MainAdminRoute extends Component {
	render() {
		const { match, containerClassnames } = this.props;
		return (
			<div id="app-container" className={containerClassnames}>
				<TopNav history={this.props.history} />
				<Sidebar />
				<main>
					<div className="container-fluid">
						<Switch>
							<Route path={`${match.url}/dashboards`} component={Dashboards} />
							<Route path={`${match.url}/users`} component={Utilisateurs} />
							<Route path={`${match.url}/payment`} component={PaymentUI} />
							<Route
								path={`${match.url}/categories`}
								component={ContentManagement}
							/>
							<Route path={`${match.url}/activities`} component={Activities} />
							<Route
								path={`${match.url}/details`}
								component={DetailActivities}
							/>
							<Route
								path={`${match.url}/activity`}
								component={DetailActivityComponent}
							/>
							<Redirect to="/error" />
						</Switch>
					</div>
				</main>
			</div>
		);
	}
}
const mapStateToProps = ({ menu, auth }) => {
	const { containerClassnames } = menu;
	const { user } = auth;
	return { containerClassnames, currentUser: user };
};
export default withRouter(connect(mapStateToProps, {})(MainAdminRoute));

import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

import PolarAreaChart from "./PolarAreaChart";
import { ThemeColors } from "./ThemeColors";
const colors = ThemeColors();

const ChartAccountTypes = ({ chartClass = "chart-container", label, data }) => {
	const polarAreaChartData = {
		labels: label,
		datasets: [
			{
				data: data,
				borderWidth: 2,
				borderColor: [
					colors.themeColor1,
					colors.themeColor2,
					colors.themeColor3
				],
				backgroundColor: [
					colors.themeColor1_10,
					colors.themeColor2_10,
					colors.themeColor3_10
				]
			}
		]
	};
	return (
		<Card className="h-100">
			<CardBody>
				<CardTitle color="secondary">
					<div className="d-inline-block">
						<h5 className="font-weight-bold">
							<p>Type de compte:</p>
						</h5>
						<span className="text-muted text-small d-block"></span>
					</div>
				</CardTitle>
				<div className={chartClass}>
					<PolarAreaChart shadow data={polarAreaChartData} />
				</div>
			</CardBody>
		</Card>
	);
};

export default ChartAccountTypes;

import { UpdateUserToken } from "api/profile";
import { updateTokenSuccess } from "../actions";

export const handleUpdateTokenAsync = (
	accessToken,
	userdata
) => async dispatch => {
	try {
		let response = await UpdateUserToken(accessToken, userdata);
		dispatch(updateTokenSuccess(response));
		return response;
	} catch (error) {
		throw error;
	}
};

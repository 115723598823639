import React, { Component, Fragment } from "react";
//Ux
import {
	Row,
	Col,
	Nav,
	NavItem,
	TabContent,
	TabPane
	//,Button
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

//Custom components
import BreadcrumbContainer from "components/BreadcrumbContainer";
import SmallCard from "components/DashbordCard/SmallCard.js";
import { Colxx } from "components/CustomBootstrap";
//Redux
import { connect } from "react-redux";
//API
import { getAllCategories } from "api/category";
import { getUserActivities } from "api/profile";
import { getActivitiesWithAddress } from "api/activities";
//img user
import logo from "assets/img/user-13-icon-256.png";
import locationMaker from "assets/img/locationMaker.png";

class Activities extends Component {
	/**
	 * constructor
	 * @param {object} props
	 */
	constructor(props) {
		super(props);
		this.toggleTab = this.toggleTab.bind(this);
		this.state = {
			activeFirstTab: "1" /*initialize tab index to 1*/,
			usersActivities: [],
			categoriesActivities: [],
			locationActivities: [],
			category: false,
			isLoading: true
		};
	}
	/**
	 * Toggle switch the nav tab
	 * @param {String} tab index of tab
	 */

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
	}
	/**
	 * Render categories card
	 */
	categoriesCards = () => {
		let exception = this.state.categoriesActivities;
		var activitiesArray = exception.sort(function(a, b) {
			return b.Activities.length - a.Activities.length;
		});
		console.log("activitiesArray", activitiesArray);
		return activitiesArray.map(element => (
			<Col sm="3" xs="12" key={element.categoryId}>
				<SmallCard
					key={element.categoryId}
					history={this.props.history}
					redirectLink="/admin/app/details"
					accessToken={this.props.currentUser.id}
					title={element.name}
					modelName="experts"
					icon={element.Cover.name}
					activitiesNbr={element.Activities.length}
					activitiesArray={element.Activities}
					container={"category"}
					avatar={true}
					detail={true}
				/>
			</Col>
		));
	};
	/**
	 * Render User card
	 */
	usersCards = () => {
		let exception = this.state.usersActivities;
		var newArray = exception.filter(item => item.Activities.length > 0);
		var activitiesArray = newArray.sort(function(a, b) {
			return b.Activities.length - a.Activities.length;
		});
		console.log("activitiesArray == ", activitiesArray);
		return activitiesArray.map((element, index) => (
			<Col sm="3" xs="12" key={index}>
				<SmallCard
					key={element.userId}
					history={this.props.history}
					redirectLink="/admin/app/details/all"
					accessToken={this.props.currentUser.id}
					title={
						(element.firstName != null ? element.firstName : "") +
						" " +
						(element.lastName != null ? element.lastName : "")
					}
					modelName="experts"
					icon={
						element.Avatar || element.providerAvatarURL
							? element.Avatar
								? element.Avatar.name
								: element.providerAvatarURL
							: logo
					}
					container={"profile"}
					avatar={element.Avatar ? true : false}
					activitiesNbr={element.Activities.length}
					activitiesArray={element.Activities}
					detail={true}
				/>
				{/*
				<ExcelFile
					element={
						<Button className="w-35" color="secondary" outline>
							Exporter en Excel
						</Button>
					}
				>
					<ExcelSheet data={activitiesArray} name="userExcel">
						<ExcelColumn label="FirstName" value="firstName" />
						<ExcelColumn label="Last Name" value="lastName" />
						<ExcelColumn label="Activitées" value="Activities.length" />
						<ExcelColumn
							label="Activitées Créées"
							value={col =>
								col.Activities.length > -1 ? `${col.Activities.length}` : "0"
							}
						/>
						<ExcelColumn
							label="Poster au moins une photo"
							value={col => {
								let a = col.Activities.map(ele => {
									let a = ele.ActivityPictures.length > 0 ? true : false;
									return a;
								});
							}}
						/>
						 <ExcelColumn label="Activitées créées" value="Activities.length" /> 
					</ExcelSheet>
				</ExcelFile>
				*/}
			</Col>
		));
	};
	/**
	 * Render location card
	 */
	locationsCards = () => {
		let exception = this.state.locationActivities;
		var activitiesArray = Object.values(exception).sort(function(a, b) {
			return b.length - a.length;
		});

		var ee = Object.values(activitiesArray).map(element => {
			var rslt = Object.values(element).filter(casa => {
				return casa.Activities.length > 0;
			});
			if (rslt.length > 0) return rslt;
		});
		console.log(ee);
		return Object.values(ee).map((element, index) => {
			if (element !== null && element !== undefined) {
				return (
					<Col sm="3" xs="12" key={index}>
						<SmallCard
							key={index}
							history={this.props.history}
							redirectLink="/admin/app/details/all"
							accessToken={this.props.currentUser.id}
							title={element[0].countryName}
							modelName="experts"
							icon={locationMaker}
							style={{
								width: 100,
								height: 100,
								marginTop: "4px"
							}}
							container={"profile"}
							activitiesNbr={element.length}
							activitiesArray={element}
							avatar={false}
							detail={false}
						/>
					</Col>
				);
			}
		});
	};

	/**
	 * Get all activities are grouped with creator
	 * @param {String} accessToken user Token
	 */
	async getUserActivities(accessToken) {
		try {
			let result = await getUserActivities({ accessToken });
			console.log("users", result);
			this.setState({ usersActivities: result, isLoading: false });
		} catch (error) {
			throw error;
		}
	}
	/**
	 * Get all activities are grouped with category
	 * @param {String} accessToken user Token
	 */
	async getCategoryActivities(accessToken) {
		try {
			let result = await getAllCategories(accessToken);
			this.setState({ categoriesActivities: result, isLoading: false });
		} catch (error) {
			throw error;
		}
	}
	/**
	 *  Grouped the activities with country name
	 * @param {Array} arrayTest
	 */
	groupedActivitiesLocation(arrayTest) {
		const groupedActivitiesLocation = arrayTest.reduce(
			(
				catsSoFar,
				{
					countryName = [],
					Activities,
					addressId,
					fullAddress,
					lattitude,
					longitude
				}
			) => {
				if (!catsSoFar[countryName]) catsSoFar[countryName] = [];
				catsSoFar[countryName].push({
					countryName,
					Activities,
					addressId,
					fullAddress,
					lattitude,
					longitude
				});
				return catsSoFar;
			},
			{}
		);
		this.setState({ locationActivities: groupedActivitiesLocation });
	}
	/**
	 *  Get all activities are grouped with address
	 * @param {string} accessToken
	 */
	async getActivitiesWithAddress(accessToken) {
		try {
			let result = await getActivitiesWithAddress({ accessToken });

			var arrayTest = result.map(element => {
				var index;
				if (element.fullAddress.lastIndexOf(",") > -1) {
					index = element.fullAddress.lastIndexOf(",");
				} else if (element.fullAddress.lastIndexOf("،") > -1) {
					index = element.fullAddress.lastIndexOf("،");
				} else {
					index = element.fullAddress.lastIndexOf("-");
				}
				var nbrCharacters = element.fullAddress[index + 1] === " " ? 2 : 1;
				var countryName = element.fullAddress.slice(index + nbrCharacters);
				if (countryName === "Tunisia") {
					countryName = "Tunisie";
				} else if (
					countryName === "SingapourSingapore" ||
					countryName === "Marina Bay Sands Hotel Tower 3"
				) {
					countryName = "Singapour";
				} else if (countryName === "- Dubaï - Émirats arabes unis") {
					countryName = "United Arab Emirates";
				} else if (countryName === "Nicole Diving Center") {
					countryName = "Thaïlande";
				} else if (countryName === "Turkey") {
					countryName = "Turquie";
				}
				var el = element;
				el["countryName"] = countryName;
				return el;
			});
			this.groupedActivitiesLocation(arrayTest);
		} catch (error) {
			throw error;
		}
	}
	/*
		
	*/

	componentDidMount() {
		const { id: accessToken } = this.props.currentUser;
		this.getCategoryActivities(accessToken);
		this.getUserActivities(accessToken);
		this.getActivitiesWithAddress(accessToken);
	}
	//
	render() {
		const { isLoading, usersActivities } = this.state;
		return (
			<Fragment>
				<Row>
					<div className="full-width">
						<Colxx xxs="12">
							<div className="mb-2 d-flex justify-content-between">
								<div>
									<BreadcrumbContainer
										heading={"Liste des activités"}
										match={this.props.match}
									/>
								</div>
							</div>
							<Nav tabs className="separator-tabs ml-0 mb-5">
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeFirstTab === "1",
											"nav-link": true
										})}
										onClick={() => {
											this.toggleTab("1");
										}}
										to="#"
									>
										{"Catégories"}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeFirstTab === "2",
											"nav-link": true
										})}
										onClick={() => {
											this.toggleTab("2");
										}}
										to="#"
									>
										{"Utilisateurs"}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeFirstTab === "3",
											"nav-link": true
										})}
										onClick={() => {
											this.toggleTab("3");
										}}
										to="#"
									>
										{"Localisation"}
									</NavLink>
								</NavItem>
							</Nav>
						</Colxx>
					</div>
				</Row>
				{isLoading ? (
					<div className="loading" />
				) : (
					<TabContent activeTab={this.state.activeFirstTab}>
						<TabPane tabId="1">
							<Row>{this.categoriesCards()}</Row>
						</TabPane>
						<TabPane tabId="2">
							<Row>{this.usersCards()}</Row>
						</TabPane>
						<TabPane tabId="3">
							<Row>{this.locationsCards()}</Row>
						</TabPane>
					</TabContent>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, null)(Activities);

import { urlApi } from "constants/defaultValues";

/**
 * Count instances of the model matched by where from the data source
 * @param {String} accessToken
 * @param {*String modelName
 */
export const countModel = async (accessToken, modelName) => {
	try {
		const response = await fetch(urlApi + `/${modelName}/count`, {
			method: "GET",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 *
 * @param {String} accessToken
 * @param {String} modelName
 * @param {String} dateOperation
 */

export const countModelWithDateOperation = async (
	accessToken,
	modelName,
	dateOperation
) => {
	try {
		const response = await fetch(
			urlApi + `/${modelName}/count/${dateOperation}`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

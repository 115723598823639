import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
//costum component
import ListPageHeading from "components/ListPageHeading";
import ContextMenuContainer from "components/ContextMenuContainer";
import ThumbListView from "../../../../components/ThumbListView";
import AddNewModal from "../../../../components/AddNewModal";
//import Pagination from "../../../../components/Pagination";
//API
import { getAllCategories, getAllCategoriesWithActivities } from "api/category";
//Redux
import { connect } from "react-redux";
import {
	handleAddNewcategoryAsync,
	handleUpdateCategoryAsync
} from "redux/actions";
//Toast
import { toast } from "react-toastify";
//
function collect(props) {
	return { data: props.data };
}

class Exceptions extends Component {
	//
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		this.handleSubmitCreateNew = this.handleSubmitCreateNew.bind(this);
		this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
		this.confirmInsertNewCategory = this.confirmInsertNewCategory.bind(this);
		this.confirmUpdateCategory = this.confirmUpdateCategory.bind(this);
		this.state = {
			displayMode: "thumblist",
			selectedPageSize: 5,
			orderOptions: [
				{ column: "name", label: "Nom" },
				{ column: "category", label: "Category" }
			],
			pageSizes: [5, 10, 15],

			categories: [
				{ label: "Cakes", value: "Cakes", key: 0 },
				{ label: "Cupcakes", value: "Cupcakes", key: 1 },
				{ label: "Desserts", value: "Desserts", key: 2 }
			],

			selectedOrderOption: { column: "category", label: "Categorie" },
			dropdownSplitOpen: false,
			modalOpen: false,
			alertOpen: false,
			currentPage: 1,
			totalItemCount: 0,
			totalPage: 1,
			search: "",
			selectedItems: [],
			lastChecked: null,
			isLoading: false,
			categoryName: "",
			categoryName_EN: "",
			hasLevel: 1,
			IsOnline: 0,
			imageFile: null,
			file: null,
			imagePreviewUrl: "",
			items: [],
			categoryToEdit: null
		};
	}
	//
	componentDidMount() {
		this.dataListRender();
	}
	//
	componentWillUnmount() {}

	setCategoryName = event => {
		this.setState({ categoryName: event.target.value });
	};
	setCategoryName_EN = event => {
		this.setState({
			categoryName_EN: event.target.value
		});
	};
	setIsOnline = event => {
		console.log("setIsOnline <-==->", event.target.value);
		//let val = event.target.value === 1 ? true : false;
		this.setState({ IsOnline: event.target.value });
	};
	setHasLevel = event => {
		console.log("setHasLevel <-==->", event.target.value);
		//let val = event.target.value === 1 ? true : false;
		this.setState({ hasLevel: event.target.value });
	};
	/**
	 * Image chosen by the user
	 */
	setImageFile = e => {
		var file = e.target.files[0];
		var value = e.target.value;
		var reader = new FileReader();
		reader.onload = e => {
			this.setState({
				imageFile: file,
				file: value
			});
		};
		if (e) {
			reader.readAsDataURL(file);
			this.setState({
				imageFile: file,
				file: value
			});
		}
	};

	/** The state of making the modal**/
	toggleModal = () => {
		this.setState(prevState => ({
			modalOpen: !prevState.modalOpen
		}));
	};
	/** dismiss modal */
	dismissModal = () => {
		this.setState(prevState => ({
			modalOpen: !prevState.modalOpen,
			categoryToEdit: null,
			categoryName: "",
			categoryName_EN: "",
			selectedItems: [],
			lastChecked: null
		}));
	};
	/**
	 * handle Submit
	 * @param {event} event
	 * @param {event} errors
	 * @param {event} values
	 */
	handleSubmitUpdate(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			//submit
			this.confirmUpdateCategory();
		}
	}

	handleSubmitCreateNew(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			//submit
			this.confirmInsertNewCategory();
		}
	}

	/**
	 * Update category data
	 */
	confirmUpdateCategory = () => {
		const { id: accessToken } = this.props.currentUser;
		const {
			IsOnline,
			hasLevel,
			categoryName_EN,
			categoryName,
			imageFile,
			categoryToEdit
		} = this.state;
		//const imageFileData = imageFile;
		const categoryData = {
			name: categoryName,
			IsOnline,
			hasLevel: hasLevel,
			name_en: categoryName_EN
		};
		/** Dispatch the add action  **/
		this.props
			.handleUpdateCategoryAsync(
				accessToken,
				categoryToEdit.coverId,
				categoryData,
				categoryToEdit.categoryId,
				categoryToEdit.Cover.name,
				imageFile
			)
			.then(() => {
				this.dataListRender(); /** Refresh the data */
				this.toggleModal(); /** Dismiss the modal */
				this.setState({
					selectedItems: [],
					categoryToEdit: null,
					categoryName: "",
					categoryName_EN: ""
				});

				//toast.success("Hello ! Nous apprécions votre retour !");
			})
			.catch(error => {
				throw error;
			});
	};

	/***
	 * Add new category
	 * 1.get image
	 * 2.handleAddNewcategoryAsync
	 */
	confirmInsertNewCategory = () => {
		const { id: accessToken } = this.props.currentUser;
		const {
			hasLevel,
			IsOnline,
			categoryName_EN,
			categoryName,
			imageFile
		} = this.state;
		console.log("hasLevel", hasLevel);
		const imageFileData = imageFile;
		const categoryData = {
			name: categoryName,
			coverId: null,
			IsOnline,
			hasLevel: hasLevel,
			name_en: categoryName_EN
		};

		/** Dispatch the add action  **/
		this.props
			.handleAddNewcategoryAsync(accessToken, categoryData, imageFileData)
			.then(() => {
				this.dataListRender(); /** Refresh the data */
				this.toggleModal(); /** Dismiss the modal */
				this.setState({
					categoryName: "",
					categoryName_EN: ""
				});
			})
			.catch(error => {
				throw error;
			});
	};
	/***
	 *Edit a checker category
	 */
	editCategory = () => {
		let selectedItems = this.state.selectedItems;
		let categories = this.state.items;
		console.log("selectedItems", selectedItems);
		if (selectedItems[0]) {
			let selectedItem = selectedItems[0];
			let categoryToEdit = categories.find(element => {
				return element.categoryId === selectedItem;
			});

			this.setState({
				categoryToEdit,
				categoryName_EN: categoryToEdit.name_en,
				categoryName: categoryToEdit.name
			});
		} else {
			console.log("no data");
		}
		this.toggleModal();
		//console.log("cat", this.state.categoryToEdit);
	};
	/***
	 * Change the sort order
	 */
	changeOrderBy = column => {
		this.setState(
			{
				isLoading: false,
				selectedOrderOption: this.state.orderOptions.find(
					x => x.column === column
				)
			},
			() => this.dataListRender()
		);
	};
	/***
	 * Change Page size
	 */
	changePageSize = size => {
		this.setState(
			{
				selectedPageSize: size,
				currentPage: 1
			},
			() => this.dataListRender()
		);
	};
	/***
	 * Change the page
	 */
	onChangePage = page => {
		this.setState(
			{
				currentPage: page
			},
			() => this.dataListRender()
		);
	};
	/***
	 * search with keyword
	 */
	onSearchKey = e => {
		if (e.key === "Enter") {
			this.setState(
				{
					search: e.target.value.toLowerCase()
				},
				() => this.dataListRender()
			);
		}
	};
	/***
	 * Check item
	 * Fiil selectedItems array
	 * @param {event} event
	 * @param {string} id category id
	 ***/
	onCheckItem = (event, id) => {
		if (
			event.target.tagName === "A" ||
			(event.target.parentElement && event.target.parentElement.tagName === "A")
		) {
			return true;
		}
		if (this.state.lastChecked === null) {
			this.setState({
				lastChecked: id
			});
		}
		let selectedItems = this.state.selectedItems;
		/* Select already selected item */
		if (selectedItems.includes(id)) {
			this.setState({
				selectedItems: [],
				categoryToEdit: null,
				lastChecked: null
			});
		} else {
			/* Select new item */
			selectedItems = [];
			selectedItems.push(id);
			this.setState({
				selectedItems
			});
		}

		if (event.shiftKey) {
			console.log("even.shiftKey");
			var items = this.state.items;
			var start = this.getIndex(id, items, "id");
			var end = this.getIndex(this.state.lastChecked, items, "id");
			items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
			selectedItems.push(
				...items.map(item => {
					return item.categoryId;
				})
			);
			selectedItems = Array.from(new Set(selectedItems));
			this.setState({
				selectedItems
			});
		}
		document.activeElement.blur();
	};
	/***
	 * Get item of selected items
	 */
	getIndex(value, arr, prop) {
		for (var i = 0; i < arr.length; i++) {
			if (arr[i][prop] === value) {
				return i;
			}
		}
		return -1;
	}
	/***
	 * Selecte all items
	 */
	handleChangeSelectAll = isToggle => {
		if (this.state.selectedItems.length >= this.state.items.length) {
			if (isToggle) {
				this.setState({
					selectedItems: []
				});
			}
		} else {
			this.setState({
				selectedItems: this.state.items.map(x => x.categoryId)
			});
		}
		document.activeElement.blur();
		return false;
	};
	/**
	 * Get categories data
	 * Get All categories included Activities and cover data
	 */
	dataListRender() {
		const { id: accessToken } = this.props.currentUser;
		const { search, selectedOrderOption } = this.state;
		try {
			getAllCategoriesWithActivities(accessToken).then(categories => {
				console.log("categories", categories);

				var arraySorted = [];

				if (selectedOrderOption.column === "name") {
					arraySorted = categories.sort(function(a, b) {
						var textA = a.name.toUpperCase();
						var textB = b.name.toUpperCase();
						return textA < textB ? -1 : textA > textB ? 1 : 0;
					});
				} else if (selectedOrderOption.column === "category") {
					arraySorted = categories.sort(function(a, b) {
						return b.Activities.length - a.Activities.length;
					});
				}

				this.setState({
					totalPage: arraySorted.length,
					items: arraySorted,
					selectedItems: [],
					totalItemCount: arraySorted.length,
					isLoading: true
				});
			});
		} catch (error) {
			throw error;
		}
	}

	/**
	 * Context menuaction
	 * @param: e, data, target
	 */
	onContextMenuClick = (e, data, target) => {
		console.log(
			"onContextMenuClick - selected items",
			this.state.selectedItems
		);
		console.log("onContextMenuClick - action : ", data.action);
	};
	/**
	 * Context menuaction
	 * @param: e, data
	 */
	onContextMenu = (e, data) => {
		const clickedProductId = data.data;
		if (!this.state.selectedItems.includes(clickedProductId)) {
			this.setState({
				selectedItems: [clickedProductId]
			});
		}

		return true;
	};
	/**
	 * Render
	 */
	render() {
		const {
			currentPage,
			items,
			displayMode,
			selectedPageSize,
			totalItemCount,
			selectedOrderOption,
			selectedItems,
			orderOptions,
			pageSizes,
			modalOpen,
			categoryToEdit,
			imageFile,
			categoryName_EN,
			categoryName,
			alertOpen
		} = this.state;
		const { match } = this.props;
		const startIndex = (currentPage - 1) * selectedPageSize;
		const endIndex = currentPage * selectedPageSize;

		return (
			<Fragment>
				<div className="disable-text-selection">
					<ListPageHeading
						heading="Liste des catégories"
						displayMode={displayMode}
						// changeDisplayMode={this.changeDisplayMode}
						handleChangeSelectAll={this.handleChangeSelectAll}
						changeOrderBy={this.changeOrderBy}
						// changePageSize={this.changePageSize}
						// selectedPageSize={selectedPageSize}
						totalItemCount={totalItemCount}
						selectedOrderOption={selectedOrderOption}
						match={match}
						// startIndex={startIndex}
						// endIndex={endIndex}
						selectedItemsLength={selectedItems ? selectedItems.length : 0}
						itemsLength={items ? items.length : 0}
						onSearchKey={this.onSearchKey}
						orderOptions={orderOptions}
						//pageSizes={pageSizes}
						toggleModal={this.toggleModal}
						buttonIsHidden={false}
						editCategory={this.editCategory}
					/>
					<AddNewModal
						modalOpen={modalOpen}
						toggleModal={this.dismissModal}
						setCategoryName_EN={this.setCategoryName_EN}
						setCategoryName={this.setCategoryName}
						setIsOnline={this.setIsOnline}
						setHasLevel={this.setHasLevel}
						setImageFile={this.setImageFile}
						confirm={this.confirmInsertNewCategory}
						imageFile={imageFile !== null ? imageFile.name : ""}
						categoryToEdit={categoryToEdit}
						editAction={this.confirmUpdateCategory}
						categoryName_EN={categoryName_EN}
						categoryName={categoryName}
						handleSubmit={
							categoryToEdit && categoryToEdit !== null
								? this.handleSubmitUpdate
								: this.handleSubmitCreateNew
						}
					/>
					{!this.state.isLoading ? (
						<div className="loading" />
					) : (
						<Row>
							{items.map(category => {
								//console.log("cate", category);
								return (
									<ThumbListView
										key={category.categoryId}
										product={category}
										isSelect={this.state.selectedItems.includes(
											category.categoryId
										)}
										collect={collect}
										onCheckItem={this.onCheckItem}
									/>
								);
							})}{" "}
							{/* <Pagination
							currentPage={this.state.currentPage}
							totalPage={this.state.totalPage}
							onChangePage={i => this.onChangePage(i)}
						/> */}
							<ContextMenuContainer
								onContextMenuClick={this.onContextMenuClick}
								onContextMenu={this.onContextMenu}
							/>
						</Row>
					)}
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, {
	handleAddNewcategoryAsync,
	handleUpdateCategoryAsync
})(Exceptions);

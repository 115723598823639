import React from "react";
import {
	Card,
	CardBody,
	UncontrolledDropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu
} from "reactstrap";

import AreaChart from "./Area";
import { ThemeColors } from "../ChartAccountTypes/ThemeColors";
const colors = ThemeColors();

const AppVisitsChartCard = props => {
	//var data = [50, 67, 50, 60, 60, 50, 85];
	const { data, labels } = props;
	const chartData = {
		labels: labels,
		//"Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
		datasets: [
			{
				label: "",
				data: data,
				borderColor: colors.themeColor2,
				pointBackgroundColor: colors.foregroundColor,
				pointBorderColor: colors.themeColor2,
				pointHoverBackgroundColor: colors.themeColor2,
				pointHoverBorderColor: colors.foregroundColor,
				pointRadius: 4,
				pointBorderWidth: 2,
				pointHoverRadius: 5,
				fill: true,
				borderWidth: 2,
				backgroundColor: colors.themeColor2_10
			}
		]
	};
	return (
		<Card className="dashboard-filled-line-chart">
			<CardBody>
				<div className="float-left float-none-xs">
					<div className="d-inline-block">
						<h5 className="font-weight-bold">Nombre d'activités créées</h5>
						{/* <span className="text-muted text-small d-block">
							<p>Grouper par: catégorie</p>
						</span> */}
					</div>
				</div>

				<div className="btn-group float-right float-none-xs mt-2">
					<UncontrolledDropdown>
						<DropdownToggle caret color="secondary" className="btn-xs" outline>
							{"Cette semaine"}
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem>{"La semaine dernière"}</DropdownItem>
							<DropdownItem>{"Ce mois-ci"}</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
			</CardBody>

			<div className="chart card-body pt-0">
				<AreaChart shadow data={chartData} />
			</div>
		</Card>
	);
};

export default AppVisitsChartCard;

import React from "react";

const TopCategoryItems = ({ data, index }) => {
	return (
		<div key={"TopCategoryItem" + index} className="ml-3 ">
			<img
				src={
					process.env.REACT_APP_URL_API +
					"/storage/category/download/" +
					data.Cover.name
				}
				alt={data.name}
				style={{
					width: 120,
					height: 100,
					borderRadius: 12,
					borderWidth: 1,
					marginLeft: -5
				}}
			/>
			<h6 className="mb-1 mt-1">
				<span className="mr-2">{index}.</span>
				{data.name}
			</h6>
			{/* <Rating total={5} rating={rate} interactive={false} /> */}
			<p className="text-small text-muted mb-0 d-inline-block">
				{data.Activities.length} Activités créées
			</p>
		</div>
	);
};

export default TopCategoryItems;

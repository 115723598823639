import { urlApi } from "constants/defaultValues";

/**
 * Get all payment transactions
 * GET query
 * @param {String} accessToken user token
 */
export const getAllPaymentTransactions = async ({ accessToken, cond = "" }) => {
	try {
		const response = await fetch(
			urlApi +
				`/payment/?filter={ 
				"include":
				   { 
					  "relation":"fkPaymentProfilerel",
					  "scope":{ 
						 "include":"Avatar"
					  }
				   },
				   "where":{${cond}}

			 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		console.log(response);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

//Lien pour accéder à l'API
export const urlApi = process.env.REACT_APP_URL_API;
//Lien du server du backend
export const urlBackend = process.env.REACT_APP_URL_BACKEND;
export const defaultStartPath = "/app/dashboards";
export const defaultStartPathAdmin = "/admin/app/dashboards";
export const urlRegistreGoogleAuth = {
	Experts: urlBackend + "/auth/expert/create-account/google",
	Customers: urlBackend + "/auth/customer/create-account/google"
};
export const urlRegistreLinkedinAuth = {
	Experts: urlBackend + "/auth/expert/create-account/linkedin",
	Customers: urlBackend + "/auth/customer/create-account/linkedin"
};
export const urlLoginGoogleAuth = {
	Experts: urlBackend + "/auth/expert/login/google",
	Customers: urlBackend + "/auth/customer/login/google"
};
export const urlLoginLinkedinAuth = {
	Experts: urlBackend + "/auth/expert/login/linkedin",
	Customers: urlBackend + "/auth/customer/login/linkedin"
};

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultMenuType = "menu-default"; //'menu-sub-hidden', 'menu-hidden'
export const searchPath = "/";

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_EXPERT_APIKEY,
	authDomain: process.env.REACT_APP_EXPERT_AUTHDOMAIN,
	databaseURL: process.env.REACT_APP_EXPERT_DATABASEURL,
	projectId: process.env.REACT_APP_EXPERT_PROJECTID,
	storageBucket: "",
	messagingSenderId: process.env.REACT_APP_EXPERT_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_EXPERT_APPID
};

export const firebaseConfigCustomer = {
	apiKey: process.env.REACT_APP_CUSTOMER_APIKEY,
	authDomain: process.env.REACT_APP_CUSTOMER_AUTHDOMAIN,
	databaseURL: process.env.REACT_APP_CUSTOMER_DATABASEURL,
	projectId: process.env.REACT_APP_CUSTOMER_PROJECTID,
	storageBucket: "",
	messagingSenderId: process.env.REACT_APP_CUSTOMER_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_CUSTOMER_APPID
};

export const fieldTypes = [
	{
		value: "text",
		label: "Texte"
	},
	{
		value: "switch",
		label: "Switch"
	},
	{
		value: "select",
		label: "Drop Down"
	}
];

export const stripe_apiKey = "pk_test_x7YNZYM5htUjUaiXbGPtSs4P002dsF6ndp";
export const defaultDirection = "ltr";

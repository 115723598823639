import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { configureStore } from "./redux/store";
import AdminApp from "./admin/containers/AdminApp";
//see nested route
const MainApp = () => (
	<Provider store={configureStore()}>
		<Router>
			{/*Switch: Renders the first child <Route> or <Redirect> that matches the location.*/}
			<Switch>
				<Route path="/admin" component={AdminApp} />
			</Switch>
		</Router>
	</Provider>
);

export default MainApp;

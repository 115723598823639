import { urlApi } from "constants/defaultValues";

/**
 * Get all data from category included Activities and Cover
 * GET query
 * @param {String} accessToken
 */

export const getAllCategoriesWithActivities = async (accessToken = null) => {
	try {
		const response = await fetch(
			urlApi +
				`/category/?filter={ 
				"include":[ 
				   { 
					  "relation":"Activities"
				   },
				   { 
					  "relation":"Cover"
				   }
				]
			 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
//filter[include]=Activities&filter[include]=Cover
export const getAllCategories = async (accessToken = null) => {
	try {
		const response = await fetch(
			urlApi +
				`/category/?filter={ 
				"include":[ 
				   { 
					  "relation":"Activities",
					  "scope":{ 
						 "include":["Category","Address","Creator", "Pictures", {"relation":"Comments", "scope":{"include":{"relation":"Commentator", "scope":{"include":"Avatar"}}}}, {"relation":"Participants", "scope":{"include": [{"relation":"Invited", "scope":{"include": "Avatar"}}]}}, {"relation":"RequestsToParticipate", "scope":{"include": [{"relation":"Invited", "scope":{"include": "Avatar"}}]}}, "ActivityPictures",
						 { 
							"relation":"Category",
							"scope":{ 
							   "include":"Cover"
							}
						 }
						]

					  }
				   },
				   { 
					  "relation":"Cover"
					
				   }
				]
			 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 * get activities with category id
 * GET query
 * @param {String} accessToken
 * @param {String} categoryID
 */
export const getCountActivitiesWithCategoryID = async (
	accessToken = null,
	categoryID
) => {
	try {
		const response = await fetch(
			urlApi + `/category/${categoryID}/Activities/count`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 * Get the cover image
 * Get query
 * @param {Strin} accessToken
 * @param {String} categoryID
 */
export const getCoverCategoryID = async (accessToken = null, categoryID) => {
	try {
		const response = await fetch(urlApi + `/category/${categoryID}/Cover`, {
			method: "GET",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 * Add new category
 * Put query
 * @param {String} accessToken user token
 * @param {Object} categoryData category data
 */
export const insertNewCategory = async (accessToken = null, categoryData) => {
	try {
		const response = await fetch(urlApi + `/category`, {
			method: "PUT",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(categoryData)
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 * Add Asset of category
 * Put query
 * @param {String} accessToken user token
 * @param {Object} assetData asset data
 */
export const insertNewAsset = async (accessToken = null, assetData) => {
	try {
		const response = await fetch(urlApi + `/Assets`, {
			method: "PUT",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(assetData)
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 * Upload the category image in the storage file
 * POST query
 * @param {String} accessToken user token
 * @param {File} imageFileData image
 */
export const uploadImage = async (accessToken = null, imageFileData) => {
	try {
		var formData = new FormData();
		formData.append("type", "file");
		formData.append("file", imageFileData);
		console.log("imageFileData API ==> ", imageFileData);
		console.log("formData API ==> ", formData);
		const response = await fetch(urlApi + `/storage/category/upload`, {
			method: "POST",
			headers: {
				Authorization: accessToken
			},
			body: formData
		});
		console.log("response uploadImage ===>, ", response);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		console.log(data);
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 * Delet image with name from storage file
 * DELETE query
 * @param {String} accessToken user token
 * @param {String} imageName image
 */

export const deleteImage = async (accessToken, imageName) => {
	try {
		const response = await fetch(
			urlApi + `/storage/category/files/${imageName}`,
			{
				method: "DELETE",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 * Update the category data
 * PATCH query
 * @param {String} accessToken
 * @param {Object} categoryData
 * @param {String} categoryId
 */
export const UpdateCategoryData = async (
	accessToken,
	categoryData,
	categoryId
) => {
	try {
		const response = await fetch(urlApi + `/category/${categoryId}`, {
			method: "PATCH",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(categoryData)
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 * Delet image with name from storage file
 * DELETE query
 * @param {String} accessToken user token
 * @param {String} imageName image
 * @param {String} assetData image
 */

export const UpdateAsset = async (accessToken, assetData, assetId) => {
	try {
		const response = await fetch(urlApi + `/Assets/${assetId}`, {
			method: "PATCH",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(assetData)
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

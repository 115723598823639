import React from "react";
import { Button, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
//Form validation
import {
	AvForm,
	AvGroup,
	AvInput,
	AvFeedback,
	AvRadioGroup,
	AvRadio
} from "availity-reactstrap-validation";

const AddNewModal = ({
	modalOpen,
	toggleModal,
	setCategoryName_EN,
	setCategoryName,
	setHasLevel,
	setIsOnline,
	setImageFile,
	imageFile,
	categoryToEdit,
	categoryName_EN,
	categoryName,
	handleSubmit
}) => {
	const defaultValues = {
		checkboxCustomInputExample:
			categoryToEdit && categoryToEdit !== null
				? categoryToEdit.hasLevel
					? 1
					: 0
				: null,

		checkboxIsOnline:
			categoryToEdit && categoryToEdit !== null
				? categoryToEdit.IsOnline
					? 1
					: 0
				: null
	};
	/**
	 * Return form to edit or/and edit category
	 */
	const renderFormComponent = () => {
		return (
			<AvForm
				className="av-tooltip tooltip-label-right"
				model={defaultValues}
				onSubmit={handleSubmit}
			>
				<AvGroup>
					<Label>Nom en français</Label>
					<AvInput
						name="nameFr"
						onChange={setCategoryName}
						value={categoryName}
						required
					/>
					<AvFeedback>Please enter category name!</AvFeedback>
				</AvGroup>
				<AvGroup>
					<Label className="mt-4">Nom en anglais</Label>
					<AvInput
						name="nameEn"
						onChange={setCategoryName_EN}
						value={categoryName_EN}
						required
					/>
					<AvFeedback>Please enter category name!</AvFeedback>
				</AvGroup>
				<AvRadioGroup
					className="error-l-150"
					name="checkboxCustomInputExample"
					required
				>
					<Label className="mt-4">La catégorie a un niveau</Label>
					<AvRadio
						customInput
						name="isHaveLevel"
						label="Oui"
						value={1}
						inline
						onChange={setHasLevel}
					/>
					<AvRadio
						customInput
						name="isNotHaveLevel"
						label="Non"
						value={0}
						onChange={setHasLevel}
					/>
				</AvRadioGroup>
				<AvRadioGroup className="error-l-150" name="checkboxIsOnline" required>
					<Label className="mt-4">Online</Label>
					<AvRadio
						customInput
						label="Oui"
						value={1}
						inline
						onChange={setIsOnline}
					/>
					<AvRadio customInput label="Non" value={0} onChange={setIsOnline} />
				</AvRadioGroup>
				<AvGroup className="mb-3" onChange={setImageFile} required>
					<Label className="mt-4">Photo de couverture</Label>
					<AvInput
						name="inputFile"
						type="file"
						id="imageCategory"
						label={imageFile !== "" ? imageFile : "Choisir une image"}
						accept="image/png, image/jpeg, image/jpg"
					/>
					<AvFeedback>Please enter category name!</AvFeedback>
				</AvGroup>
				<Button color="info" outline>
					Confirmer
				</Button>{" "}
			</AvForm>
		);
	};
	/**
	 * Return category's image
	 */
	const renderImageComponent = () => {
		return (
			<div className="d-flex justify-content-center">
				{categoryToEdit && categoryToEdit !== null ? (
					<img
						alt={""}
						src={
							process.env.REACT_APP_URL_API +
							"/storage/category/download/" +
							categoryToEdit.Cover.name
						}
						className="responsive border-0 mb-4"
						style={{
							height: 150,
							width: 150
						}}
					/>
				) : null}
			</div>
		);
	};
	return (
		<Modal
			isOpen={modalOpen}
			toggle={toggleModal}
			wrapClassName="modal-right"
			backdrop="static"
		>
			<ModalHeader toggle={toggleModal}>
				{categoryToEdit && categoryToEdit !== null
					? "Modifier " + categoryToEdit.name
					: "Ajouter un nouveau"}
			</ModalHeader>
			{renderImageComponent()}
			<ModalBody>{renderFormComponent()}</ModalBody>
		</Modal>
	);
};

export default AddNewModal;

import React from "react";
import { Route, Switch } from "react-router-dom";
import Categories from "./categories/All";
function ContentManagement({ match }) {
	return (
		<>
			<Switch>
				<Route path={`${match.url}`} component={Categories} />
			</Switch>
		</>
	);
}
export default ContentManagement;

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {
	setContainerClassnames,
	addContainerClassname,
	changeDefaultClassnames
} from "redux/actions";

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.addEvents = this.addEvents.bind(this);
		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.toggle = this.toggle.bind(this);
		this.handleProps = this.handleProps.bind(this);
		this.removeEvents = this.removeEvents.bind(this);
		this.getContainer = this.getContainer.bind(this);
		this.getMenuClassesForResize = this.getMenuClassesForResize.bind(this);
		this.setSelectedLiActive = this.setSelectedLiActive.bind(this);

		this.state = {
			selectedParentMenu: "",
			viewingParentMenu: ""
		};
	}

	handleWindowResize(event) {
		if (event && !event.isTrusted) {
			return;
		}
		const { containerClassnames } = this.props;
		let nextClasses = this.getMenuClassesForResize(containerClassnames);
		this.props.setContainerClassnames(0, nextClasses.join(" "));
	}

	handleDocumentClick(e) {
		const container = this.getContainer();
		let isMenuClick = false;
		if (
			e.target &&
			e.target.classList &&
			(e.target.classList.contains("menu-button") ||
				e.target.classList.contains("menu-button-mobile"))
		) {
			isMenuClick = true;
		} else if (
			e.target.parentElement &&
			e.target.parentElement.classList &&
			(e.target.parentElement.classList.contains("menu-button") ||
				e.target.parentElement.classList.contains("menu-button-mobile"))
		) {
			isMenuClick = true;
		} else if (
			e.target.parentElement &&
			e.target.parentElement.parentElement &&
			e.target.parentElement.parentElement.classList &&
			(e.target.parentElement.parentElement.classList.contains("menu-button") ||
				e.target.parentElement.parentElement.classList.contains(
					"menu-button-mobile"
				))
		) {
			isMenuClick = true;
		}
		if (
			(container.contains(e.target) && container !== e.target) ||
			isMenuClick
		) {
			return;
		}
		this.toggle(e);
		this.setState({
			viewingParentMenu: ""
		});
	}

	getMenuClassesForResize(classes) {
		const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
		let nextClasses = classes.split(" ").filter(x => x != "");
		const windowWidth = window.innerWidth;
		if (windowWidth < menuHiddenBreakpoint) {
			nextClasses.push("menu-mobile");
		} else if (windowWidth < subHiddenBreakpoint) {
			nextClasses = nextClasses.filter(x => x != "menu-mobile");
			if (
				nextClasses.includes("menu-default") &&
				!nextClasses.includes("menu-sub-hidden")
			) {
				nextClasses.push("menu-sub-hidden");
			}
		} else {
			nextClasses = nextClasses.filter(x => x != "menu-mobile");
			if (
				nextClasses.includes("menu-default") &&
				nextClasses.includes("menu-sub-hidden")
			) {
				nextClasses = nextClasses.filter(x => x != "menu-sub-hidden");
			}
		}
		return nextClasses;
	}

	getContainer() {
		return ReactDOM.findDOMNode(this);
	}

	toggle() {
		const { containerClassnames, menuClickCount } = this.props;
		const currentClasses = containerClassnames
			? containerClassnames.split(" ").filter(x => x != "")
			: "";

		if (currentClasses.includes("menu-sub-hidden") && menuClickCount == 3) {
			this.props.setContainerClassnames(2, containerClassnames);
		} else if (
			currentClasses.includes("menu-hidden") ||
			currentClasses.includes("menu-mobile")
		) {
			this.props.setContainerClassnames(0, containerClassnames);
		}
	}

	handleProps() {
		this.addEvents();
	}

	addEvents() {
		["click", "touchstart"].forEach(event =>
			document.addEventListener(event, this.handleDocumentClick, true)
		);
	}
	removeEvents() {
		["click", "touchstart"].forEach(event =>
			document.removeEventListener(event, this.handleDocumentClick, true)
		);
	}
	setSelectedLiActive() {
		const oldli = document.querySelector(".sub-menu  li.active");
		if (oldli != null) {
			oldli.classList.remove("active");
		}

		/* set selected parent menu */
		const selectedlink = document.querySelector(".sub-menu  a.active");
		if (selectedlink != null) {
			selectedlink.parentElement.classList.add("active");
			this.setState({
				selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
					"data-parent"
				)
			});
		} else {
			var selectedParentNoSubItem = document.querySelector(
				".main-menu  li a.active"
			);
			if (selectedParentNoSubItem != null) {
				this.setState({
					selectedParentMenu: selectedParentNoSubItem.getAttribute("data-flag")
				});
			} else if (this.state.selectedParentMenu == "") {
				this.setState({
					selectedParentMenu: "gogo"
				});
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setSelectedLiActive();
			this.toggle();
			window.scrollTo(0, 0);
		}

		this.handleProps();
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleWindowResize);
		this.handleWindowResize();
		this.handleProps();
		this.setSelectedLiActive();
	}

	componentWillUnmount() {
		this.removeEvents();
		window.removeEventListener("resize", this.handleWindowResize);
	}

	changeDefaultMenuType(e, containerClassnames) {
		e.preventDefault();
		let nextClasses = this.getMenuClassesForResize(containerClassnames);
		this.props.setContainerClassnames(0, nextClasses.join(" "));
	}

	openSubMenu(e, selectedParent) {
		e.preventDefault();
		const { containerClassnames, menuClickCount } = this.props;
		const currentClasses = containerClassnames
			? containerClassnames.split(" ").filter(x => x != "")
			: "";

		if (!currentClasses.includes("menu-mobile")) {
			if (
				currentClasses.includes("menu-sub-hidden") &&
				(menuClickCount == 2 || menuClickCount == 0)
			) {
				this.props.setContainerClassnames(3, containerClassnames);
			} else if (
				currentClasses.includes("menu-hidden") &&
				(menuClickCount == 1 || menuClickCount == 3)
			) {
				this.props.setContainerClassnames(2, containerClassnames);
			} else if (
				currentClasses.includes("menu-default") &&
				!currentClasses.includes("menu-sub-hidden") &&
				(menuClickCount == 1 || menuClickCount == 3)
			) {
				this.props.setContainerClassnames(0, containerClassnames);
			}
		} else {
			this.props.addContainerClassname(
				"sub-show-temporary",
				containerClassnames
			);
		}
		this.setState({
			viewingParentMenu: selectedParent
		});
	}

	render() {
		return (
			<div className="sidebar">
				<div className="main-menu">
					<div className="scroll">
						<PerfectScrollbar
							option={{ suppressScrollX: true, wheelPropagation: false }}
						>
							<Nav vertical className="list-unstyled">
								<NavItem
									className={classnames({
										active:
											(this.state.selectedParentMenu === "gogo" &&
												this.state.viewingParentMenu === "") ||
											this.state.viewingParentMenu === "gogo"
									})}
								>
									<NavLink to="/admin/app/dashboards">
										<i className="iconsminds-monitor-analytics" /> Tableau de
										bord
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										active:
											(this.state.selectedParentMenu === "gogo" &&
												this.state.viewingParentMenu === "") ||
											this.state.viewingParentMenu === "gogo"
									})}
								>
									<NavLink to="/admin/app/users">
										<i className="iconsmind-User" /> Utilisateurs
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										active:
											(this.state.selectedParentMenu === "gogo" &&
												this.state.viewingParentMenu === "") ||
											this.state.viewingParentMenu === "gogo"
									})}
								>
									<NavLink to="/admin/app/payment">
										<i className="iconsminds-money-bag" /> Paiement
									</NavLink>
								</NavItem>
								<NavItem
									className={classnames({
										active:
											(this.state.selectedParentMenu ===
												"content-management-menu" &&
												this.state.viewingParentMenu === "") ||
											this.state.viewingParentMenu === "content-management-menu"
									})}
								>
									<NavLink
										to="/app"
										onClick={e =>
											this.openSubMenu(e, "content-management-menu")
										}
									>
										<i className="iconsminds-management" /> Gestion <br />
										des contenu
									</NavLink>
								</NavItem>

								{/* <NavItem
									className={classnames({
										active:
											(this.state.selectedParentMenu == "security-menu" &&
												this.state.viewingParentMenu == "") ||
											this.state.viewingParentMenu == "security-menu"
									})}
								>
									<NavLink
										to="/app/security-menu"
										onClick={e => this.openSubMenu(e, "security-menu")}
									>
										<i className="iconsminds-security-settings" /> Activités
									</NavLink>
								</NavItem> */}
							</Nav>
						</PerfectScrollbar>
					</div>
				</div>

				<div className="sub-menu">
					<div className="scroll">
						<PerfectScrollbar
							option={{ suppressScrollX: true, wheelPropagation: false }}
						>
							<Nav
								className={classnames({
									"d-block":
										(this.state.selectedParentMenu ===
											"content-management-menu" &&
											this.state.viewingParentMenu === "") ||
										this.state.viewingParentMenu === "content-management-menu"
								})}
								data-parent="content-management-menu"
							>
								<NavItem>
									<NavLink to="/admin/app/categories">
										<i className="simple-icon-paper-plane" />
										Gestion des catégories
									</NavLink>
									<NavLink to="/admin/app/activities">
										<i className="iconsminds-project" /> Gestion des Activités
									</NavLink>
								</NavItem>
							</Nav>

							<Nav
								className={classnames({
									"d-block":
										(this.state.selectedParentMenu === "security-menu" &&
											this.state.viewingParentMenu === "") ||
										this.state.viewingParentMenu === "security-menu"
								})}
								data-parent="security-menu"
							>
								{/* <NavItem>
									<NavLink to="/admin/app/activities/all">
										<i className="iconsminds-project" /> Gestion des Activités
									</NavLink>
									<NavLink to="/admin/app/statistics/all">
										<i className="iconsminds-statistic" /> Statistiques
									</NavLink>
								</NavItem> */}
							</Nav>
						</PerfectScrollbar>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ menu, auth }) => {
	const { user } = auth;
	const {
		containerClassnames,
		subHiddenBreakpoint,
		menuHiddenBreakpoint,
		menuClickCount
	} = menu;
	return {
		containerClassnames,
		subHiddenBreakpoint,
		menuHiddenBreakpoint,
		menuClickCount,
		currentUser: user
	};
};
export default withRouter(
	connect(mapStateToProps, {
		setContainerClassnames,
		addContainerClassname,
		changeDefaultClassnames
	})(Sidebar)
);

import React, { Component, Fragment } from "react";
//Utilities
import { isValidEmail, handleInputError } from "util/Validation";
//Redux
import { connect } from "react-redux";
import { handleLoginAsync } from "redux/actions";
//Message d'exception
import messagesExceptionApi from "lng/exceptions";
//
import Logo from "assets/img/logo-white.png";
//UX
import {
	Row,
	Card,
	CardTitle,
	Form,
	Label,
	Input,
	Button,
	Spinner
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "components/CustomBootstrap";
//Toast
import { toast } from "react-toastify";

class LoginLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			email: "",
			password: "",
			errors: []
		};
	}
	/**
	 *
	 * @param {Object} event
	 */
	handleFormElements(event) {
		this.setState({ [event.target.name]: event.target.value });
	}
	/**
	 * Validation formulaire
	 * @returns Boolean
	 */
	isValidForm() {
		let errors = {};
		const { email, password } = this.state;
		if (!isValidEmail(email)) {
			errors.email = { message: "Email pas valide" };
		}
		if (password.length < 1) {
			errors.password = { message: "Le champ mot de passe est requis" };
		}
		if (Object.entries(errors).length !== 0) {
			return this.setState({ errors }, () => false);
		}
		return true;
	}

	/**
	 * Action submit du formulaire
	 * @param Object event
	 */
	handleSubmit(event) {
		event.preventDefault();
		if (this.isValidForm()) {
			this.setState({ isLoading: true }, () =>
				this.handleLoginWithEmailAndPassword()
			);
		}
	}

	/**
	 *
	 */
	handleLoginWithEmailAndPassword() {
		let profileType = "user";
		const { email, password } = this.state;
		this.props
			.handleLoginAsync(email, password, profileType)
			.then(() => {
				this.setState({ isLoading: false, errors: [] });
				//	toast.success("Hello ! Nous apprécions votre retour !");
				this.props.history.push("/admin/app/dashboards");
			})
			.catch(({ error = {} }) => {
				console.log(error);
				this.setState({ isLoading: false, errors: [] });
				let { code = "API_ERROR" } = error;
				console.log(error, messagesExceptionApi[code]);
				//toast.error(messagesExceptionApi[code]);
			});
	}

	componentDidMount() {
		document.body.classList.add("background");
	}
	componentWillUnmount() {
		document.body.classList.remove("background");
	}
	render() {
		const { isLoading, email, password, errors } = this.state;
		return (
			<Fragment>
				<div className="fixed-background" />
				<main>
					<div className="container">
						<Row className="h-100">
							<Colxx xxs="12" md="10" className="mx-auto my-auto">
								<Card className="auth-card">
									<div className="position-relative image-side " />
									<div className="form-side">
										<NavLink to={`/admin`}>
											<img src={Logo} className="logo-single" alt="yepwego" />
										</NavLink>
										<CardTitle className="mb-4">
											{" "}
											Espace d'administration
										</CardTitle>
										<Form onSubmit={this.handleSubmit.bind(this)}>
											<Label className="form-group has-float-label mb-4">
												<Input
													name={"email"}
													type={"text"}
													placeholder={"Email"}
													value={email}
													invalid={handleInputError(errors, "email")}
													onChange={this.handleFormElements.bind(this)}
												/>
												<span>Email</span>
											</Label>
											<Label className="form-group has-float-label mb-4">
												<Input
													name={"password"}
													type={"password"}
													placeholder={"Mot de passe"}
													value={password}
													onChange={this.handleFormElements.bind(this)}
													invalid={handleInputError(errors, "password")}
												/>
												<span>Mot de passe</span>
											</Label>
											<div className="d-flex justify-content-between align-items-center">
												{/* <NavLink to={`/forgot-password`}>
													Mot de passe oublié ?
												</NavLink> */}
												{isLoading ? (
													<Spinner color="secondary" />
												) : (
													<Button
														color="primary"
														outline
														type="submit"
														disabled={isLoading}
													>
														Connexion
													</Button>
												)}
											</div>
										</Form>
									</div>
								</Card>
							</Colxx>
						</Row>
					</div>
				</main>
			</Fragment>
		);
	}
}

export default connect(null, { handleLoginAsync })(LoginLayout);

import React from "react";
import { Card, CustomInput, Badge } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "components/CustomBootstrap";

const ThumbListView = ({ product, isSelect, collect, onCheckItem }) => {
	return (
		<Colxx xxs="12" key={product.categoryId} className="mb-3">
			<ContextMenuTrigger
				id="menu_id"
				data={product.categoryId}
				collect={collect}
			>
				<Card
					onClick={event => onCheckItem(event, product.categoryId)}
					className={classnames("d-flex flex-row", {
						active: isSelect
					})}
				>
					<NavLink to={`?p=${product.categoryId}`} className="d-flex">
						<img
							alt={product.name}
							src={
								process.env.REACT_APP_URL_API +
								"/storage/category/download/" +
								product.Cover.name
							}
							className="list-thumbnail responsive border-0 card-img-left"
							style={{
								height: 100,
								width: 250
							}}
						/>
					</NavLink>
					<div className="pl-2 d-flex flex-grow-1 min-width-zero">
						<div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
							<NavLink
								to={`?p=${product.categoryId}`}
								className="w-40 w-sm-100"
							>
								<p className="list-item-heading mb-4 truncate">
									{product.name}
								</p>
							</NavLink>
							<p className="mb-1 text-muted font-semibold w-15 w-sm-100">
								{product.Activities.length + " Activités créées"}
							</p>
							<p className="mb-1 text-muted text-small w-15 w-sm-100">
								{product.hasLevel}
							</p>
							<div className="w-15 w-sm-100">
								<Badge color={"primary"} pill>
									{product.hasLevel === true ? "A un niveau" : ""}
								</Badge>
							</div>
						</div>
						<div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
							<CustomInput
								className="item-check mb-0 "
								type="checkbox"
								id={`check_${product.categoryId}`}
								checked={isSelect}
								onChange={() => {}}
								label=""
							/>
						</div>
					</div>
				</Card>
			</ContextMenuTrigger>
		</Colxx>
	);
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ThumbListView);

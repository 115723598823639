import { urlApi } from "constants/defaultValues";

const getAllReportReason = async ({
	accessToken,
	profileType = "reportReasons"
}) => {
	try {
		const response = await fetch(urlApi + `/${profileType}`, {
			method: "GET",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

const getAllReportUser = async ({
	accessToken,
	profileType = "reportUser"
}) => {
	try {
		const response = await fetch(urlApi + `/${profileType}`, {
			method: "GET",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

export { getAllReportReason, getAllReportUser };

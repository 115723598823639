import {
	LOGOUT_USER,
	LOGIN_USER_SUCCESS,
	UPDATE_USER_SUCCESS,
	SET_USER_TEMP_DATA,
	EMPTY_USER_TEMP_DATA
} from "constants/actionTypes";

const INIT_STATE = {
	user: null,
	tempUser: {},
	loading: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case LOGIN_USER_SUCCESS:
			let { user } = action;
			return { ...state, user };
		case LOGOUT_USER:
			return { ...state, user: null };
		case UPDATE_USER_SUCCESS:
			if (action.user) {
				return { ...state, user: { ...state.user, user: action.user } };
			}
			return { ...state };
		case SET_USER_TEMP_DATA:
			let { tempUser } = action;
			return {
				...state,
				tempUser: { ...state.tempUser, ...tempUser }
			};
		case EMPTY_USER_TEMP_DATA:
			return { ...state, tempUser: {} };
		default:
			return { ...state };
	}
};

import { urlApi } from "constants/defaultValues";

/**
 * Get all users
 * @param {String} accessToken
 * @param {String} profileType
 */
const getAllUsers = async ({
	accessToken,
	profileType = "user",
	cond = "",
	orderOption = ""
}) => {
	try {
		const response = await fetch(
			urlApi +
				`/${profileType}/?filter={ 
                    "include":
                       { 
                          "relation":"Profile" 
                       } ,"where":{"id": {"neq":1} ${cond} }${orderOption}
                 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 * count user with condition
 * @param {String} accessToken
 * @param {String} condition
 */

const getAllUserWithCondition = async (accessToken, condition) => {
	try {
		const response = await fetch(
			urlApi + `/user/count/?${condition}&[where][id][neq]=1`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 *
 * @param {*} accessToken user token
 * @param {String} profileType model name
 * @param {Number} currentPage current page number
 * @param {Number} records_per_page number of record per page
 * @param {String} search
 * @param {String} orderOption sorting type
 */
const getUsersWithLimitSkip = async ({
	accessToken,
	profileType = "user",
	currentPage,
	records_per_page,
	search,
	orderOption
}) => {
	try {
		const searchFilter =
			search !== "" ? "&filter[where][email][like]=%" + search + "%" : "";
		const orderBy =
			orderOption !== null && (orderOption === "google" || orderOption === "fb")
				? "&filter[where][email][like]=%" +
				  orderOption +
				  "%&filter[order]=createdAt%20DESC"
				: "";
		const orderOptions =
			orderOption !== null &&
			(orderOption === "createdAt" || orderOption === "firstName")
				? "&filter[order]=" + orderOption + "%20DESC"
				: "";
		const response = await fetch(
			urlApi +
				`/${profileType}/?filter[include]=Profile${searchFilter}${orderOptions}${orderBy}&filter[limit]=${records_per_page}&filter[skip]=${(currentPage -
					1) *
					records_per_page}&filter[where][id][neq]=1`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 *
 * @param {String} accessToken user Token
 * @param {String} avatarID
 * @param {String} profileType model name
 */
const getUserContainer = async ({
	accessToken,
	avatarID,
	profileType = "assets"
}) => {
	try {
		const response = await fetch(urlApi + `/${profileType}/${avatarID}`, {
			method: "GET",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 *
 * @param {*} param0
 */
const getUserAvatar = async ({
	accessToken,
	profileName,
	profileType = "storage/profile/files"
}) => {
	try {
		const response = await fetch(urlApi + `/${profileType}/${profileName}`, {
			method: "GET",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		//console.log("data===> \n", data);
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 *
 * @param {*} param0
 */
const getUsersInArray = async ({
	accessToken,
	profileType = "user",
	idUsersBlocked
}) => {
	console.log("Api ===>   ", idUsersBlocked);
	try {
		const response = await fetch(
			urlApi +
				`/${profileType}/?filter[include]=Profile&filter[where][id][in]=${[
					123,
					334
				]}`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 *
 * @param {*} param0
 */
const getFilterProfiles = async ({
	accessToken,
	profileType = "user",
	currentPage,
	records_per_page,
	search,
	orderOption,
	condition
}) => {
	try {
		//const orderOptions = `"order":"orderOptions%20Asc"`;
		console.log("------------------");
		console.log("getFilterProfiles");
		console.log(condition);
		console.log("------------------");
		const response = await fetch(
			urlApi +
				`/${profileType}/?filter={ 
					"include":[{"relation":"Profile","scope":{"include":["Activities","Avatar", "Cover","Categories","FollowRequest","Followers","ParticipatedActivities","ReceivedFollowRequest","BlockedProfile"]}}], "where":{"id": {"neq":1} ${condition} },
					"limit":${records_per_page},
                    "skip":${(currentPage - 1) * records_per_page}
                    ${orderOption}
					
				 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);

		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 * {"include":"Profile",    "where": {  "and":  [  {"createdAt": {"gt": "2019-11-01T00:00:00.00Z"}},  {"createdAt": {"lt": "2019-11-26T00:00:00.00Z"}} ]        } }
 */
export {
	getAllUsers,
	getAllUserWithCondition,
	getUsersWithLimitSkip,
	getUserContainer,
	getUserAvatar,
	getUsersInArray,
	getFilterProfiles
};

import React, { Component, Fragment } from "react";
//UI
import {
	Row,
	Table,
	Card,
	CardBody,
	CardSubtitle,
	CardText,
	Button,
	Popover,
	PopoverBody,
	Nav,
	NavItem,
	TabContent,
	TabPane
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
//date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
//nav bar
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//UI components
import { Colxx, Separator } from "components/CustomBootstrap";
import BreadcrumbContainer from "components/BreadcrumbContainer";
import Imagecard from "components/DashbordCard/ImageCard";
//Redux
import { connect } from "react-redux";
//moment
import moment from "moment";
//image default user
import logo from "assets/img/user-13-icon-256.png";
//API
import { getAllPaymentTransactions } from "api/payment";
//Excel
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//user placeholder

class PaymentUI extends Component {
	constructor(props) {
		super(props);
		this.toggleTab = this.toggleTab.bind(this);
		this.state = {
			activeFirstTab: "1" /*initialize tab index to 1*/,
			isLoading: true,
			paymentTranstctionsList: [],
			groupedPaymentUser: [],
			endDateRange: null,
			startDateRange: null,
			isFiltered: false
		};
	}
	/**
	 * Toggle switch the nav tab
	 * @param {String} tab index of tab
	 */
	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
	}
	cancelFilter = accessToken => {
		this.setState({
			endDateRange: null,
			startDateRange: null,
			isFiltered: false,
			paymentTranstctionsList: [],
			groupedPaymentUser: []
		});
		this.getAllPaymentTransactions(accessToken, "");
	};
	/**
	 * Get all payment transactions
	 * @param {String} accessToken user token
	 */

	async getAllPaymentTransactions(accessToken, cond) {
		try {
			let result = await getAllPaymentTransactions({ accessToken, cond });
			const groupedPaymentUser = result.reduce(
				(catsSoFar, { fkPaymentProfilerel = [], userId, paymentId }) => {
					if (!catsSoFar[userId]) catsSoFar[userId] = [];
					catsSoFar[userId].push({
						fkPaymentProfilerel,
						userId,
						paymentId
					});
					return catsSoFar;
				},
				{}
			);

			this.setState({
				paymentTranstctionsList: result,
				groupedPaymentUser: groupedPaymentUser,
				isLoading: false
			});
		} catch (error) {
			throw error;
		}
	}
	/**
	 * render transaction table
	 */
	dateCard = () => {
		let paymentArray = this.state.paymentTranstctionsList;
		var arraySorted = paymentArray.sort(function(a, b) {
			return new Date(b.createdAt) - new Date(a.createdAt);
		});
		return (
			<Table striped>
				<thead>
					<tr>
						<th></th>
						<th>Date de transaction</th>
						<th>Utilisateur</th>
					</tr>
				</thead>
				<tbody>
					{arraySorted.map((element, index) => (
						<tr key={index}>
							<th scope="row">{index + 1}</th>
							<td>
								{moment(moment(element.createdAt)).format("DD-MM-YYYY à HH:MM")}
							</td>
							<td>
								{(element.fkPaymentProfilerel.firstName != null
									? element.fkPaymentProfilerel.firstName
									: "") +
									" " +
									(element.fkPaymentProfilerel.lastName != null
										? element.fkPaymentProfilerel.lastName
										: "")}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	};

	/**
	 * render user card
	 */
	usersCard = () => {
		let paymentArray = this.state.groupedPaymentUser;
		var arraySorted = Object.values(paymentArray).sort(function(a, b) {
			return b.length - a.length;
		});
		return Object.values(arraySorted).map((element, index) => (
			<Colxx md="6" sm="6" lg="4" xxs="12" key={index}>
				<Card className="d-flex flex-row mb-4" style={{ borderRadius: "10px" }}>
					<NavLink to="#" className="d-flex">
						<Imagecard
							alt={""}
							src={
								element[0].fkPaymentProfilerel.Avatar ||
								element[0].fkPaymentProfilerel.providerAvatarURL
									? element[0].fkPaymentProfilerel.Avatar
										? process.env.REACT_APP_URL_API +
										  "/storage/profile/download/" +
										  element[0].fkPaymentProfilerel.Avatar.name
										: element[0].fkPaymentProfilerel.providerAvatarURL
									: logo
							}
							userCard={true}
							text={
								(element[0].fkPaymentProfilerel.firstName != null
									? element[0].fkPaymentProfilerel.firstName
									: "") +
								" " +
								(element[0].fkPaymentProfilerel.lastName != null
									? element[0].fkPaymentProfilerel.lastName
									: "")
							}
							style={{ minWidth: 60, minHeight: 60 }}
							className={`img-thumbnail list-thumbnail align-self-center m-4 small rounded`}
						/>
					</NavLink>
					<div className=" d-flex flex-grow-1 min-width-zero">
						<CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
							<div className="min-width-zero">
								<NavLink to="/app/ui/cards">
									<CardSubtitle className="truncate mb-1 font-weight-bold">
										{(element[0].fkPaymentProfilerel.firstName != null
											? element[0].fkPaymentProfilerel.firstName
											: "") +
											" " +
											(element[0].fkPaymentProfilerel.lastName != null
												? element[0].fkPaymentProfilerel.lastName
												: "")}
									</CardSubtitle>
								</NavLink>
								<CardText className="text-muted text-small mb-2">
									{element.length} transactions
								</CardText>
							</div>
						</CardBody>
					</div>
				</Card>
			</Colxx>
		));
	};
	endDateRange;
	startDateRange;
	/**Change date -> start date */
	handleChangeStart = date => {
		this.setState({
			startDateRange: date
		});
	};
	/**Change date -> end date */
	handleChangeEnd = date => {
		this.setState({
			endDateRange: date
		});
	};
	/**
	 * Render date picker component
	 */
	/**
	 * render date picker -> filter user with created date
	 */
	handleSubmitDateFilter = () => {
		const { id: accessToken } = this.props.currentUser;
		let { startDateRange, endDateRange, isFiltered } = this.state;
		if (startDateRange === null || endDateRange === null) {
			this.setState({
				isFilterUsersButton: false,
				openPopoverButton: true
			});
		} else {
			if (isFiltered) {
				this.cancelFilter(accessToken);
			} else {
				let { endDateRange, startDateRange } = this.state;
				let cond = `"and":[{"createdAt": {"gt": "${moment(startDateRange).add(
					-1,
					"days"
				)}"}},  {"createdAt": {"lt": "${moment(endDateRange).add(
					1,
					"days"
				)}"}}]`;
				this.getAllPaymentTransactions(accessToken, cond);
				this.setState({
					isFiltered: true,
					isLoading: true
				});
			}
		}
		setTimeout(() => {
			this.setState({
				openPopoverButton: false /*close popover button*/
			});
		}, 1000);
	};
	renderDatePicker = () => {
		return (
			<Row className="ml-2">
				<div className="mb-5 d-inline-block  float-md-left mr-1 mb-1 align-top"></div>
				<div className="mb-5 d-inline-block  float-md-left mr-1 mb-1 align-top">
					<DatePicker
						selected={this.state.startDateRange}
						selectsStart
						startDate={this.state.startDateRange}
						endDate={this.state.endDateRange}
						onChange={this.handleChangeStart}
						placeholderText="Date début"
						maxDate={
							this.state.endDateRange !== null
								? this.state.endDateRange
								: new Date()
						}
					/>
				</div>
				<div className=" d-inline-block  float-md-left mr-1 mb-1 align-top"></div>
				<div className=" float-md-left mr-1 mb-1 align-top">
					<DatePicker
						selected={this.state.endDateRange}
						selectsEnd
						startDate={this.state.startDateRange}
						endDate={this.state.endDateRange}
						onChange={this.handleChangeEnd}
						placeholderText="date fin"
						maxDate={new Date()}
					/>
				</div>
				<div className=" float-md-left mr-1 mb-1 align-top">
					<Button
						id="filterUsersButton"
						// inline
						onClick={this.handleSubmitDateFilter}
					>
						<span className="label">
							{this.state.isFiltered ? "Annuler" : "Confirmer"}
						</span>
						<Popover
							placement="top"
							isOpen={this.state.openPopoverButton}
							target={"filterUsersButton"}
						>
							<PopoverBody>
								{this.state.isFilterUsersButton ? " succés" : "Erreur"}
							</PopoverBody>
						</Popover>
					</Button>
				</div>
			</Row>
		);
	};
	componentDidMount() {
		const { id: accessToken } = this.props.currentUser;
		console.log("payment UI = ", accessToken);
		this.getAllPaymentTransactions(accessToken, "");
	}
	render() {
		const { isLoading } = this.state;
		return (
			<Fragment>
				<Row>
					<div className="disable-text-selection full-width">
						<Colxx xxs="12">
							<div className="mb-2 d-flex justify-content-between">
								<div>
									<BreadcrumbContainer
										heading={"Transaction de paiement"}
										match={this.props.match}
									/>
								</div>
							</div>
							<Nav tabs className="separator-tabs ml-0 mb-5">
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeFirstTab === "1",
											"nav-link": true
										})}
										onClick={() => {
											this.toggleTab("1");
										}}
										to="#"
									>
										{"Utilisateurs"}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeFirstTab === "2",
											"nav-link": true
										})}
										onClick={() => {
											this.toggleTab("2");
										}}
										to="#"
									>
										{"Toutes transactions"}
									</NavLink>
								</NavItem>
							</Nav>
							{this.renderDatePicker()}
						</Colxx>
					</div>
				</Row>

				{isLoading ? (
					<div className="loading" />
				) : (
					<TabContent activeTab={this.state.activeFirstTab}>
						<TabPane tabId="1">
							<Row>{this.usersCard()}</Row>
						</TabPane>
						<TabPane tabId="2">
							<Row>{this.dateCard()}</Row>
						</TabPane>
					</TabContent>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, null)(PaymentUI);

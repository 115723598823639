import React from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Table,
	Row,
	Label,
	Popover,
	PopoverBody
} from "reactstrap";
import classnames from "classnames";
//Excel
import ReactExport from "react-export-excel";
//custom components
import Imagecard from "components/DashbordCard/ImageCard";
//default user image
import logo from "assets/img/user-13-icon-256.png";
//Form validation
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
//Excel File
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//Row of table
const tableRow = (label, value, iconName) => {
	return (
		<tr>
			<th scope="row" className="ml-1 w-40">
				<i className={iconName} /> {label}
			</th>
			<td>{value}</td>
		</tr>
	);
};

const DetailUserModal = ({
	modalOpen,
	toggleModal,
	user,
	isOpenCollapse,
	toggleCollapse,
	handleSubmit, //add token
	setUserToken,
	isLoadingTokenButton,
	tokensToAdd
}) => {
	var dataExcel = [];
	dataExcel.push(user);

	return (
		<Modal
			isOpen={modalOpen}
			toggle={toggleModal}
			wrapClassName="modal-right"
			backdrop="static"
		>
			<ModalHeader style={{ height: 20 }} toggle={toggleModal} />

			<ModalBody className="pt-0 mt-0">
				<Row
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Imagecard
						alt={""}
						src={
							user.Avatar || user.providerAvatarURL
								? user.Avatar
									? process.env.REACT_APP_URL_API +
									  "/storage/profile/download/" +
									  user.Avatar
									: user.providerAvatarURL
								: logo
						}
						//userCard={true}
						text={
							(user.firstName != null ? user.firstName : "") +
							" " +
							(user.lastName != null ? user.lastName : "")
						}
						className={`list-thumbnail align-self-center m-4 large center rounded`}
					/>
				</Row>
				<Table hover borderless>
					<tbody>
						{tableRow(
							"Nom:",
							`${user.firstName}` + " " + `${user.lastName}`,
							"simple-icon-user mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
						{tableRow(
							"E-mail:",
							`${user.email}`,
							"simple-icon-envelope mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
						{tableRow(
							"Membre depuis:",
							`${user.createdAt}`,
							"simple-icon-calendar mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
						{tableRow(
							"Dernière Login:",
							`${user.lastLogin}`,
							"simple-icon-login mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
						{tableRow(
							"Jetons:",
							`${user.tokensCount}`,
							"simple-icon-credit-card mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}

						{tableRow(
							"Participation:",
							user.ParticipatedActivities,
							"simple-icon-link mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}

						{tableRow(
							"Abonnement:",
							user.Followers,
							"simple-icon-people mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
						{tableRow(
							"Activités créée:",
							user.Activities,
							"simple-icon-note mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
						{tableRow(
							"Blockage:",
							user.BlockedProfile,
							"simple-icon-lock mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
						{tableRow(
							"Localisation:",
							"-",
							"simple-icon-location-pin mr-1 w-18 h-18",
							toggleCollapse,
							isOpenCollapse
						)}
					</tbody>
				</Table>
				<Row>
					<AvForm
						className="av-tooltip tooltip-label-right ml-4"
						inline
						onSubmit={handleSubmit}
					>
						<AvGroup className="error-t-negative">
							<Label className=" mr-4 color-theme-1 font-weight-bold">
								Jetons
							</Label>
							<AvField
								className=" mr-4"
								name="minPropNumberProp"
								type="Number"
								value={tokensToAdd}
								onChange={setUserToken}
								validate={{
									min: { value: 1, errorMessage: "Minimum 1" },
									required: {
										value: true,
										errorMessage: "Un chiffre SVP"
									}
								}}
							/>
						</AvGroup>

						<Button
							id="successButton"
							color="primary"
							className={`mb-3 ${classnames({
								"show-spinner": isLoadingTokenButton === "processing",
								"show-success": isLoadingTokenButton === "success",
								"show-fail": isLoadingTokenButton === "fail"
							})}`}
						>
							{isLoadingTokenButton !== "fail" ? (
								isLoadingTokenButton === "processing" ||
								isLoadingTokenButton === "success" ? (
									isLoadingTokenButton === "processing" ? (
										<span className="spinner d-inline-block">
											<span className="bounce1" />
											<span className="bounce2" />
											<span className="bounce3" />
											<span className="bounce4" />
										</span>
									) : null
								) : null
							) : null}
							<span className="label">Confirmer</span>

							<Popover
								placement="top"
								isOpen={
									isLoadingTokenButton === "fail" ||
									isLoadingTokenButton === "success"
										? true
										: false
								}
								target={"successButton"}
							>
								<PopoverBody>
									{isLoadingTokenButton === "fail"
										? "Erreur se produit"
										: "L'ajout a été effectué avec succés"}
								</PopoverBody>
							</Popover>
						</Button>
					</AvForm>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Row>
					<ExcelFile
						element={
							<Button className="w-35" color="secondary" outline>
								Exporter en Excel
							</Button>
						}
					>
						<ExcelSheet data={dataExcel} name="userExcel">
							<ExcelColumn label="FirstName" value="firstName" />
							<ExcelColumn label="Last Name" value="lastName" />
							<ExcelColumn
								label="Email"
								value={col =>
									col.email.indexOf("@fb") > -1 ||
									col.email.indexOf("@google") > -1
										? col.email.indexOf("@fb") > -1
											? "Facebook"
											: "Google"
										: "email"
								}
							/>
						</ExcelSheet>
					</ExcelFile>
				</Row>
			</ModalFooter>
		</Modal>
	);
};

export default DetailUserModal;

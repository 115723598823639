import { chartTooltip } from "../../util/Utils";
export const polarAreaChartOptions = {
	legend: {
		position: "bottom",
		labels: {
			padding: 30,
			usePointStyle: true,
			fontSize: 12
		}
	},
	responsive: true,
	maintainAspectRatio: false,
	scale: {
		ticks: {
			display: false
		}
	},
	plugins: {
		datalabels: {
			display: false
		}
	},
	tooltips: chartTooltip
};

import React, { Component } from "react";
//Redux
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import MainAdminRoute from "admin/routes";
import { ToastContainer } from "react-toastify";
import Login from "admin/routes/login";
import { defaultStartPathAdmin } from "constants/defaultValues";
const InitialPath = ({ component: Component, authUser, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				authUser ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/admin/connexion",
							state: { from: props.location }
						}}
					/>
				)
			}
		/>
	);
};

class AdminApp extends Component {
	render() {
		const { location, match, user = null } = this.props;
		if (
			location.pathname === "/admin" ||
			location.pathname === "/admin/" ||
			location.pathname === null
		) {
			return <Redirect to={defaultStartPathAdmin} />;
		}
		return (
			<>
				<ToastContainer
					autoClose={4000}
					position="top-left"
					hideProgressBar={false}
				/>
				<Switch>
					<InitialPath
						path={`${match.url}/app`}
						authUser={user}
						component={MainAdminRoute}
					/>
					<Route path={`${match.url}/connexion`} component={Login} />
					<Redirect to="/error" />
				</Switch>
			</>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return user;
};

export default connect(mapStateToProps, {})(AdminApp);

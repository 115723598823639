import React from "react";
import ReactDOM from "react-dom";
const rootEl = document.getElementById("root");

let render = () => {
	//Attendre jusqu'a le chargement du CSS
	//CSSBoostrap doit se charger avant le style de l'application
	import("bootstrap/dist/css/bootstrap.css").then(() => {
		import("./assets/css/sass/themes/yepwego.light.purple.scss").then(() => {
			const MainApp = require("./App").default;
			ReactDOM.render(<MainApp />, rootEl);
		});
	});
};
render();

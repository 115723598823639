import {
	REGISTER_CATEGORY,
	REGISTER_CATEGORY_SUCCESS,
	UPDATE_CATEGORY_SUCCESS
} from "constants/actionTypes";

export const registerCategory = category => ({
	type: REGISTER_CATEGORY,
	category
});
export const registerCategorySuccess = category => ({
	type: REGISTER_CATEGORY_SUCCESS,
	category
});
export const updateSuccess = category => ({
	type: UPDATE_CATEGORY_SUCCESS,
	category
});
export * from "./asyncActions";

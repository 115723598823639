import React from "react";
import { Button } from "reactstrap";
//color
let rootStyle = getComputedStyle(document.body);
const pathColor = rootStyle.getPropertyValue("--theme-color-2").trim();
const ThumbnailLetters = props => {
	const { text, color, className, small } = props;
	let letters = "";
	if (text) {
		if (text.indexOf(" ") > -1) {
			text.split(" ").map(word => {
				if (word.length > 1) letters += word.slice(0, 1);
				return "";
			});
		} else {
			letters += text.slice(0, 2);
		}
	}

	if (letters.length > 2) {
		letters = letters.slice(0, 2);
	}

	return (
		<Button
			outline
			small={small}
			style={{
				color: "white",
				backgroundColor: "#DCDCDC",
				borderWidth: 1.5,
				borderColor: `#DCDCDC`
			}}
			className={`align-self-center list-thumbnail-letters  ${
				color ? `btn-${pathColor}` : ""
			}  ${className} small`}
		>
			{letters}
		</Button>
	);
};

export default ThumbnailLetters;

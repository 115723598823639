import { urlApi } from "constants/defaultValues";

const getAllProfilesWithoutlimit = async ({
	accessToken,
	cond,
	profileType = "profile"
}) => {
	try {
		const orderOptions = `"order":"firstName%20Asc"`;
		const response = await fetch(
			urlApi +
				`/${profileType}/?filter={ 
					"include":[ 
					 
					   { 
						"relation":"User"
					 },
					 {
					  "relation":"Activities"
					 }
				
					],
				${orderOptions}
				
				 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 * get all profiles
 * @param {string} accessToken user token
 * @param {string} profileType table name
 * @param {number} currentPage number of page
 * @param {number} records_per_page limit of recorders per page
 * @param {string} search get users with first name
 * @param {string} orderOption sorted data
 *
 */
/**
 * {"include":"Profile",    "where": {  "and":  [  {"createdAt": {"gt": "2019-11-01T00:00:00.00Z"}},  {"createdAt": {"lt": "2019-11-26T00:00:00.00Z"}} ]        } }
 */
const getAllProfiles = async ({
	accessToken,
	profileType = "profile",
	currentPage,
	records_per_page,
	search,
	orderOption
}) => {
	try {
		const searchFilter =
			search !== ""
				? `"where":{"or":[{"firstName":{"regexp":"/${search}/i"}},{"lastName":{"regexp":"/${search}/i"}}]},`
				: "";

		// orderOption !== null && (orderOption === "google" || orderOption === "fb")
		// 	? "&filter[where][User][email][like]=%" +
		// 	  orderOption +
		// 	  "%&filter[order]=createdAt%20DESC"
		//	: "";
		const orderOptions = `"order":"firstName%20Asc"`;
		const response = await fetch(
			urlApi +
				`/${profileType}/?filter={ 
					"include":[ 
					   { 
						  "relation":"BlockedProfile",
						  "scope":{ 
							 "include":"fkBlockeduserUsertoblockrel"
						  }
					   },
					   { 
						  "relation":"Avatar"
					   },
					   {
						"relation":"Cover"
					   },
					   { 
						"relation":"User"
					 },
					 {
					  "relation":"Activities"
					 },
					 { 
						"relation":"Categories"
					 },
					 {
					  "relation":"FollowRequest"
					 },
					 { 
					  "relation":"ReceivedFollowRequest"
				   },
				   {
					"relation":"Followers"
				   },
				   { 
					  "relation":"ParticipatedActivities"
				   }
					],
				"limit":${records_per_page},
				"skip":${(currentPage - 1) * records_per_page},
				${searchFilter}${orderOptions}
				
				 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};
/**
 *
 * @param {*} param0
 */
/**
 * {"include":"Profile",    "where": {  "and":  [  {"createdAt": {"gt": "2019-11-01T00:00:00.00Z"}},  {"createdAt": {"lt": "2019-11-26T00:00:00.00Z"}} ]        } }
 */

/**
 * Get user included activities
 * @param {string} accessToken
 */

const getUserActivities = async ({ accessToken }) => {
	try {
		const response = await fetch(
			urlApi +
				`/profile/?filter={ 
					"include":[ 
					    { 
							"relation":"Activities",
							"scope":{ 
							   "include":["Category","Address","Creator", "Pictures", {"relation":"Comments", "scope":{"include":{"relation":"Commentator", "scope":{"include":"Avatar"}}}}, {"relation":"Participants", "scope":{"include": [{"relation":"Invited", "scope":{"include": "Avatar"}}]}}, {"relation":"RequestsToParticipate", "scope":{"include": [{"relation":"Invited", "scope":{"include": "Avatar"}}]}}, "ActivityPictures",
							   { 
								  "relation":"Category",
								  "scope":{ 
									 "include":"Cover"
			
								  }
							   }
							  ]
	  
							}
						 },
					   { 
						  "relation":"Avatar"
					   },
					   {
						"relation":"User"
					   }
					]
				 }`,
			{
				method: "GET",
				headers: {
					Authorization: accessToken,
					"Content-Type": "application/json"
				}
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

/**
 * Update user token
 * @param {string} accessToken
 * @param {object} data
 */
const UpdateUserToken = async (accessToken = null, userdata) => {
	try {
		const response = await fetch(urlApi + `/profile/updateToken`, {
			method: "POST",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(userdata)
		});
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

export {
	getAllProfiles,
	getUserActivities,
	getAllProfilesWithoutlimit,
	UpdateUserToken
};

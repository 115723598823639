import React, { Component, Fragment } from "react";
//UI components
import { Colxx, Separator } from "components/CustomBootstrap";
import BreadcrumbContainer from "components/BreadcrumbContainer";
import {
	Row,
	Card,
	CardBody,
	CardTitle,
	Button,
	Popover,
	PopoverBody
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import IconCardsCarousel from "components/IconCardsCarousel";
import ChartAccountTypes from "components/ChartAccountTypes";
//import ChartDevicesUsage from "components/ChartDevicesUsage";
// import SmallLineCharts from "components/SmallLineCharts";
import AppVisitsChartCard from "components/AreaChart";
import ReportReason from "components/ReportReason";
import GlideComponent from "components/GlideComponent";
import TopCategoryItems from "components/TopCategoryItems";
import CarrdStatictics from "components/CardStatictics";
import CardUsersBlocked from "components/CardUsersBlocked";
//ChartDeviceUsage
import { connect } from "react-redux";
//Api
import { getAllReportReason, getAllReportUser } from "api/report";
import { getAllUsers, getAllUserWithCondition } from "api/user";
import { getUserBlocked } from "api/blockedUser";
import {
	getAllCategories
	// ,getCountActivitiesWithCategoryID,
	// getCoverCategoryID
} from "api/category";
import { countModel, countModelWithDateOperation } from "api/count";
import { countActivitiesWithDate } from "api/activities";
import moment from "moment";
import { ThemeColors } from "components/ChartAccountTypes/ThemeColors";
const colors = ThemeColors();
let rootStyle = getComputedStyle(document.body);
const pathColor = rootStyle.getPropertyValue("--theme-color-2").trim();
class Dashboards extends Component {
	constructor(props) {
		super(props);
		this.handleChangeStart = this.handleChangeStart.bind(this); //Filter -> strat date
		this.handleChangeEnd = this.handleChangeEnd.bind(this); //Filter -> end date
		this.renderDatePicker = this.renderDatePicker.bind(this); //show DatePicker component
		this.handleSubmitDateFilter = this.handleSubmitDateFilter.bind(this); //filter users with a date range
		this.countTotalUser = this.countTotalUser.bind(this);
		this.state = {
			data: [],
			users: [],
			activities: [],
			totalUsers: {
				title: "Total d'utilisateurs",
				icon: "simple-icon-user",
				value: 0
			},
			totalMales: {
				title: "Total Masculin",
				icon: "simple-icon-symbol-male",
				value: 0
			},
			totalFemales: {
				title: "Total Féminin",
				icon: "simple-icon-symbol-female",
				value: 0
			},
			//acquis
			newUsers: {
				title: "Nouveaux utilisateurs",
				icon: "simple-icon-user-follow",
				value: 0
			},
			deviseUsageChartData: {
				labels: ["Android", "iOS"],
				datasets: [
					{
						label: "",
						borderColor: [colors.themeColor3, colors.themeColor2],
						backgroundColor: [colors.themeColor3_10, colors.themeColor2_10],
						borderWidth: 2,
						data: [45, 18]
					}
				]
			},
			socialMediaProvider: [0, 0, 0],
			topTreeCategories: [],
			userConnection: [],
			isLoading: true,
			reportReason: [],
			reportUser: [],
			blockedUsers: [],
			UsersWhoBlockedId: [],
			usersToBlockedId: [],
			allReportsReason: [],
			categoriesWithCount: [],
			countModel: [{}],
			activitiesModel: [],
			labelsSmallLineCharts: [],
			totalUsersSmallLineCharts: [],
			totalNewUsersSmallLineCharts: [],
			startDateRange: null, //Filter -> strat date
			endDateRange: null, //Filter -> end date
			isFilterMessageButton: false,
			openPopoverButton: false,
			cancelFilterDate: false,
			categoriesLabels: [],
			categoriesData: []
		};
	}
	/**
	 * Get user data
	 */
	isYesterday(momentDate) {
		var REFERENCE = moment();
		var YESTERDAY = REFERENCE.clone()
			.subtract(1, "days")
			.startOf("day");
		return momentDate.isSame(YESTERDAY, "d");
	}
	isToday(momentDate) {
		var REFERENCE = moment(); // fixed just for testing, use moment();
		var TODAY = REFERENCE.clone().startOf("day");
		return momentDate.isSame(TODAY, "d");
	}
	usersData() {
		const { id: accessToken } = this.props.currentUser;
		/**
		 * Get all users
		 * @param {string } accessToken user accessToken
		 */

		getAllUsers({ accessToken })
			.then(users => {
				var now = moment();

				/*** Filter the new user in current week	 */
				var newUsers = users.filter(
					item =>
						this.isYesterday(moment(item.createdAt)) ||
						this.isToday(moment(item.createdAt))
				);
				/*** Filter the male user */

				var totaleMales = users.filter(item =>
					item.Profile ? item.Profile.isMale : null
				);
				/***  filter Google accounts */
				var googleArray = users.filter(
					item => item.email.indexOf("google") > -1
				);
				/***  filter Facebook accounts */
				let facebookArray = users.filter(item => item.email.indexOf("fb") > -1);
				var socialMediaArray = [];
				/***  Filter Yepwego Form  accounts */
				let countAccount = Math.round(
					((users.length - (googleArray.length + facebookArray.length)) * 100) /
						users.length
				);
				/***  Purcent  */
				let googlePurcent = Math.round(
					(googleArray.length * 100) / users.length
				);
				let fbPurcent = Math.round((facebookArray.length * 100) / users.length);
				socialMediaArray.push(googlePurcent);
				socialMediaArray.push(fbPurcent);
				socialMediaArray.push(countAccount);

				const userConnection = users.filter(
					item => now.isoWeek() === moment(item.lastLogin).isoWeek()
				);
				/*** State */
				this.setState(prevState => ({
					totalUsers: {
						...prevState.totalUsers,
						value: users.length
					},
					newUsers: {
						...prevState.newUsers,
						value: newUsers.length
					},
					totalMales: {
						...prevState.totalMales,
						value: totaleMales.length
					},
					totalFemales: {
						...prevState.totalFemales,
						value: users.length - totaleMales.length
					},
					socialMediaProvider: socialMediaArray,
					users: users,
					userConnection
				}));
				this.usersDataForCards();
			})
			.catch(error => console.log(error));
	}
	/**
	 * Build the table of users data for card user
	 */
	usersDataForCards() {
		var newData = [];
		newData.push(this.state.totalUsers);
		newData.push(this.state.totalMales);
		newData.push(this.state.totalFemales);
		newData.push(this.state.newUsers);
		this.setState({ data: newData });
	}
	/**
	 *  1. Get users blocked
	 *  2. Groups each user blocked by who
	 * @param {String} accessToken
	 */
	getBlockedUser(accessToken) {
		getUserBlocked({ accessToken })
			.then(blockedUsers => {
				//console.log(blockedUsers);
				const groupedBlockedUsers = blockedUsers.reduce(
					(
						catsSoFar,
						{
							userToBlockId = [],
							userId,
							fkBlockeduserUserrel,
							fkBlockeduserUsertoblockrel
						}
					) => {
						if (!catsSoFar[userToBlockId]) catsSoFar[userToBlockId] = [];
						catsSoFar[userToBlockId].push({
							userToBlockId,
							userId,
							fkBlockeduserUserrel,
							fkBlockeduserUsertoblockrel
						});
						return catsSoFar;
					},
					{}
				);
				// const usersToBlockedId = Object.values(groupedBlockedUsers);
				// console.log("===", usersToBlockedId);
				this.setState({
					blockedUsers,
					usersToBlockedId: groupedBlockedUsers
				});
			})
			.catch(error => console.log("error => ", error));
	}
	/**
	 *  Get report
	 */
	reportData() {
		var reasons = this.state.reportReason;
		var reports = this.state.reportUser;
		var arrayReports = [];
		reasons.forEach(reason => {
			const d = reports.filter(report => {
				return report.reasonId === reason.reasonId;
			});
			arrayReports.push(d);
		});
		this.setState({ allReportsReason: arrayReports });
	}
	/**
	 *  Get reaport
	 *  1. get reason
	 *  2. get reaport
	 * @param {String} accessToken
	 */
	async getAllReportUsers(accessToken) {
		/*** Get reason @param: accessToken */
		try {
			// let reports = await getAllReportUser({ accessToken });
			let reasons = await getAllReportReason({ accessToken });
			var reasonsWithOther = reasons;
			reasonsWithOther.push({
				reasonId: null,
				title: "Autre",
				title_en: "Other"
			});
			/*** Get All Report @param: accessToken */
			let reports = await getAllReportUser({ accessToken });
			/*** Group the report with his reason */
			var arrayReports = [];
			reasons.forEach((reason, index) => {
				const d = reports.filter(report => {
					return report.reasonId === reason.reasonId;
				});
				arrayReports[index] = d.length;
			});
			arrayReports.push(reports.length);
			this.setState({
				reportReason: reasons,
				allReportsReason: arrayReports,
				reportUser: reports
			});
		} catch (error) {
			throw error;
		}
	}
	/**
	 * Get  activities count with date
	 * @param {String} accessToken
	 */
	async getAllActivities(accessToken) {
		let nowDate = moment(new Date());
		let sum = "";
		let quaryOperationToDo = `?[where][startAt][gt]=${nowDate}`;
		let quaryOperationTerminated = `?[where][startAt][lt]=${nowDate}`;
		let quaryOperationInProgress = `?[where][startAt]=${nowDate}`;
		//`?[where][startAt]=${nowDate}`;
		var arrayData = [];
		try {
			let totalActivitiesCreated = await countActivitiesWithDate(
				accessToken,
				sum
			);
			totalActivitiesCreated["title"] = "Activités créées";
			let toDoActivities = await countActivitiesWithDate(
				accessToken,
				quaryOperationToDo
			);
			toDoActivities["title"] = "Activités à faire";
			let completedActivities = await countActivitiesWithDate(
				accessToken,
				quaryOperationTerminated
			);
			completedActivities["title"] = "Activités terminés";
			//	console.log("completedActivities", completedActivities);
			let currentActivities = await countActivitiesWithDate(
				accessToken,
				quaryOperationInProgress
			);
			currentActivities["title"] = "Activités en cours";
			arrayData.push(
				totalActivitiesCreated,
				currentActivities,
				completedActivities,
				toDoActivities
			);
			this.setState({ activitiesModel: arrayData });
		} catch (error) {
			throw error;
		}
	}
	/**
	 * get number of payment with date
	 * @param {string} accessToken
	 */
	async countPaymentWithDate(accessToken) {
		try {
			let nowDate = new Date();
			let operation = `?[where][createdAt]=${nowDate.toJSON()}`;
			let payment = await countModelWithDateOperation(
				accessToken,
				"payment",
				operation
			);
			console.log("payment == ", payment);
		} catch (error) {
			throw error;
		}
	}
	/**
	 * 1.Get All categories
	 * 2.Get Count Activities for each CategoryID
	 * 3.Get Cover name  for each category
	 * @param {string} accessToken user token
	 */
	getAllCategoriesList(accessToken) {
		try {
			getAllCategories(accessToken).then(categoriesWithCount => {
				var arraySorted = categoriesWithCount.sort(function(a, b) {
					return b.Activities.length - a.Activities.length;
				});
				let categoriesLabels = arraySorted.filter(ele => {
					return ele.Activities.length > 5;
				});
				let categoriesData = arraySorted.filter(ele => {
					return ele.Activities.length > 5;
				});
				this.setState({
					categoriesWithCount,
					categoriesLabels,
					categoriesData
				});
			});

			// categoriesLabels: [],
			// categoriesData: []
		} catch (error) {
			throw error;
		}
	}
	/**
	 *
	 * @param {String} accessToken user token
	 */
	/**
	 * {"include":"Profile",    "where": {  "and":  [  {"createdAt": {"gt": "2019-11-01T00:00:00.00Z"}},  {"createdAt": {"lt": "2019-11-26T00:00:00.00Z"}} ]        } }
	 *
	 * ?filter[where][and][0][postDate][lt]=2016-01-27&filter[where][and][1][postDate][gt]=2016-01-26
	 */
	async countTotalUser(accessToken) {
		try {
			var label = [];
			var totalUsers = [];
			var totalNewUsers = [];
			let condition = `[where][createdAt]`;
			let firstDay = moment()
				.subtract(6, "days")
				.toDate();
			let conditionNewUser = `[where][and][0][createdAt][gt]=`;
			console.log("firstDay", firstDay.toJSON());
			for (let i = 0; i < 7; i++) {
				let date = moment()
					.subtract(i, "days")
					.toDate();

				if (i === 6) {
					firstDay.setHours(1, 0, 0, 0, 0);
					date.setHours(23, 0, 0, 0, 0);
				} else {
					firstDay.setHours(1, 0, 0, 0, 0);
					date.setHours(1, 0, 0, 0, 0);
				}
				label.push(moment(date).format("ddd")); //weekdays name
				//API
				let totalWeekProfile = await getAllUserWithCondition(
					accessToken,
					`${condition}[lt]=${date.toJSON()}`
				); //total user
				let totalWeekNewProfile = await getAllUserWithCondition(
					accessToken,
					`[where][and][0][createdAt][gt]=${firstDay.toJSON()}&[where][and][1][createdAt][lt]=${date.toJSON()}`
				); //total user

				totalUsers.push(totalWeekProfile.count);
				totalNewUsers.push(totalWeekNewProfile.count);
			}

			this.setState({
				totalUsersSmallLineCharts: totalUsers,
				totalNewUsersSmallLineCharts: totalNewUsers,
				labelsSmallLineCharts: label
			});
		} catch (error) {
			throw error;
		}
	}

	/***
	 *
	 */
	async getCountModel(accessToken) {
		try {
			var modelData = [];
			let listPayment = await countModel(accessToken, "payment");
			listPayment["title"] = "Transactions de paiement";
			let listConversation = await countModel(accessToken, "conversation");
			listConversation["title"] = "Conversations";
			let listConversationMessage = await countModel(accessToken, "messages");
			listConversationMessage["title"] = "Messages";
			let listFollowRequest = await countModel(accessToken, "followRequest");
			listFollowRequest["title"] = "Demandes de suivi";
			let listInvitationRequest = await countModel(
				accessToken,
				"invitationRequest"
			);
			listInvitationRequest["title"] =
				"Demandes d'invitation & de participation";

			modelData.push(
				listPayment,
				listConversation,
				listConversationMessage,
				listFollowRequest,
				listInvitationRequest
			);
			this.setState({ countModel: modelData, isLoading: false });
		} catch (error) {
			throw error;
		}
	}
	/**
	 * Build a week from current day
	 * @param {date} date
	 */
	// dayOfWeek() {
	// 	var label = [];
	// 	var result = [];

	// 	for (let i = 1; i < 8; i++) {
	// 		label.push(
	// 			moment(
	// 				moment()
	// 					.subtract(i, "days")
	// 					.toDate()
	// 			).format("ddd")
	// 		);
	// 		result.push(
	// 			moment()
	// 				.subtract(i, "days")
	// 				.toDate()
	// 		);
	// 	}
	// 	console.log("result", result[0]);
	// 	this.setState({
	// 		labelsSmallLineCharts: label,
	// 		datesSmallLineCharts: result
	// 	});

	// 	console.log("label", label);
	// }

	/** ComponentDidMount */
	componentDidMount() {
		const { id: accessToken } = this.props.currentUser;
		this.countTotalUser(accessToken);
		this.usersData();
		this.usersDataForCards();
		this.getAllReportUsers(accessToken);
		this.getBlockedUser(accessToken);
		this.reportData();
		this.getAllActivities(accessToken);
		this.getAllCategoriesList(accessToken);
		this.getCountModel(accessToken);
	}
	/** Render component of top categpry  */
	cardTopCategory = () => {
		const activitiesArray = this.state.categoriesWithCount;

		console.log("arraySorted", activitiesArray);
		//console.log("array Sorted ", arraySorted);
		return activitiesArray.map((element, index) => {
			if (index < 3) {
				return (
					<TopCategoryItems
						key={"item" + index}
						data={element}
						index={index + 1}
					/>
				);
			}
		});
	};
	cardModel = () => {
		let data = this.state.countModel;
		return data.map((model, index) => {
			if (index !== 1 && index !== 2)
				return (
					<CarrdStatictics
						key={"cardModel" + index}
						model={model}
						percent={false}
						pathColor={pathColor}
					/>
				);
		});
	};
	/**Change date -> start date */
	handleChangeStart = date => {
		this.setState({
			startDateRange: date
		});
	};
	/**Change date -> end date */
	handleChangeEnd = date => {
		this.setState({
			endDateRange: date
		});
	};

	/**
	 * Render date picker component
	 * filter messages with created date
	 */
	handleSubmitDateFilter = async () => {
		const { id: accessToken } = this.props.currentUser;
		if (
			this.state.startDateRange === null ||
			this.state.endDateRange === null
		) {
			this.setState({
				isFilterMessageButton: false,
				openPopoverButton: true
			});
		} else {
			try {
				if (this.state.cancelFilterDate) {
					this.cancelFilterDate(accessToken);
				} else {
					let { startDateRange, endDateRange } = this.state;
					let startDate = moment(startDateRange);
					let endDate = moment(endDateRange);
					let operation = `?[where][and][0][[createdAt][gt]=${startDate}&filter[where][and][1][[createdAt][lt]=${endDate}`;
					let messages = await countModelWithDateOperation(
						accessToken,
						"messages",
						operation
					);

					let conversation = await countModelWithDateOperation(
						accessToken,
						"conversation",
						operation
					);
					var stateCopy = Object.assign({}, this.state);
					stateCopy.countModel[2]["count"] = messages.count; //Edit number of message
					stateCopy.countModel[1]["count"] = conversation.count;
					stateCopy.cancelFilterDate = true;
					this.setState(stateCopy);
				}
			} catch (error) {
				throw error;
			}
		}
		setTimeout(() => {
			this.setState({
				openPopoverButton: false
			});
		}, 1000);
	};
	cancelFilterDate = async accessToken => {
		let listConversation = await countModel(accessToken, "conversation");
		let listConversationMessage = await countModel(accessToken, "messages");
		var stateCopy = Object.assign({}, this.state);
		stateCopy.countModel[2]["count"] = listConversationMessage.count; //Edit number of message
		stateCopy.countModel[1]["count"] = listConversation.count;
		stateCopy.cancelFilterDate = false;
		stateCopy.startDateRange = null;
		stateCopy.endDateRange = null;
		this.setState(stateCopy);
	};
	/**
	 * Render date picker component
	 */
	renderDatePicker = () => {
		return (
			<Row className="mb-3 ml-3">
				<div className=" d-inline-block  float-md-left mr-1 mb-1 align-top">
					<DatePicker
						selected={this.state.startDateRange}
						selectsStart
						startDate={this.state.startDateRange}
						endDate={this.state.endDateRange}
						onChange={this.handleChangeStart}
						placeholderText="Date début"
						maxDate={
							this.state.endDateRange !== null
								? this.state.endDateRange
								: new Date()
						}
					/>
				</div>
				<div className=" d-inline-block  float-md-left mr-1 mb-1 align-top">
					{/* <Label>À</Label> */}
				</div>
				<div className=" float-md-left mr-1 mb-1 align-top">
					<DatePicker
						selected={this.state.endDateRange}
						selectsEnd
						startDate={this.state.startDateRange}
						endDate={this.state.endDateRange}
						onChange={this.handleChangeEnd}
						placeholderText="date fin"
						maxDate={new Date()}
					/>
				</div>
				<div className=" float-md-left mr-1 mb-1 align-top">
					<Button
						id="filterUsersButton"
						// inline
						onClick={this.handleSubmitDateFilter}
					>
						<span className="label">
							{this.state.cancelFilterDate ? "Annuler" : "Confirmer"}
						</span>
						<Popover
							placement="top"
							isOpen={this.state.openPopoverButton}
							target={"filterUsersButton"}
						>
							<PopoverBody>
								{this.state.isFilterMessageButton ? " succés" : "Erreur"}
							</PopoverBody>
						</Popover>
					</Button>
				</div>
			</Row>
		);
	};
	cardMessages = () => {
		let data = this.state.countModel;
		return data.map((model, index) => {
			if (index === 1 || index === 2)
				return (
					<CarrdStatictics
						key={"cardModel" + index}
						model={model}
						percent={false}
						pathColor={pathColor}
					/>
				);
		});
	};
	cardActivitiesStatics = () => {
		let data = this.state.activitiesModel;
		console.log("this.state.modelData", this.state.activitiesModel);
		return data.map((model, index) => {
			return (
				<CarrdStatictics
					key={"cardActivitiesStatics" + index}
					model={model}
					percent={true}
					pathColor={pathColor}
					total={data[0].count}
				/>
			);
		});
	};
	render() {
		const {
			data,
			isLoading,
			socialMediaProvider,
			reportReason,
			allReportsReason,
			//deviseUsageChartData,
			blockedUsers
			// labelsSmallLineCharts,
			// totalUsersSmallLineCharts,
			// totalNewUsersSmallLineCharts,
			// userConnection,
			// categoriesWithCount,
			// categoriesLabels,
			// categoriesData
		} = this.state;
		// let labes = categoriesLabels.map(ele => {
		// 	return ele.name;
		// });
		// let datas = categoriesData.map(ele => {
		// 	return ele.Activities.length;
		// });
		const labelCompteName = ["% Google", "% Facebook", "% Yepwego"];
		return (
			<Fragment>
				<Row>
					<div className="disable-text-selection full-width">
						<Colxx xxs="12">
							<div className="mb-2 d-flex justify-content-between">
								<div>
									<BreadcrumbContainer
										heading={"Tableau de bord"}
										match={this.props.match}
									/>
								</div>
							</div>
							<Separator className="mb-5" />
						</Colxx>
					</div>
				</Row>
				{isLoading ? (
					<div className="loading" />
				) : (
					<>
						<Row>
							<Colxx lg="12" md="12" className="mb-4">
								<IconCardsCarousel data={data} />
							</Colxx>
						</Row>

						{/* <Row>
							<Colxx lg="6" md="12" className="mb-4">
								<ChartAccountTypes
									chartClass="dashboard-donut-chart"
									label={labelCompteName}
									data={socialMediaProvider}
								/>
							</Colxx>
							<Colxx lg="6" md="12" className="mb-4">
								<SmallLineCharts
									labelsSmallLineCharts={labelsSmallLineCharts}
									totalUsersSmallLineCharts={totalUsersSmallLineCharts}
									totalNewUsersSmallLineCharts={totalNewUsersSmallLineCharts}
								/>
								 <ChartDevicesUsage
									chartClass="dashboard-donut-chart mb-4"
									deviseUsageChartData={deviseUsageChartData}
								/> 
							</Colxx>
						</Row> */}
						<Row>
							<Colxx lg="4" md="12" className="mb-4">
								<ChartAccountTypes
									chartClass="dashboard-donut-chart"
									label={labelCompteName}
									data={socialMediaProvider}
								/>
							</Colxx>
							<Colxx lg="4" md="12" className="mb-4">
								<ReportReason
									data={reportReason}
									allReportsReason={allReportsReason}
								/>
							</Colxx>
							<Colxx lg="4" md="12" className="mb-4">
								<CardUsersBlocked blockedUsers={blockedUsers} />
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12" md="12" className="mb-4">
								<Card className="dashboard-top-rated h-100 mb-4">
									<CardBody>
										<CardTitle>
											<p className="font-weight-bold">Top Catégories</p>
										</CardTitle>
										<Row>
											<Colxx lg="6" md="12" className="mb-4">
												<GlideComponent
													settings={{
														gap: 5,
														perView: 1,
														type: "carousel",
														hideNav: true
													}}
												>
													<div
														key={"GlideComponent" + 0}
														className="d-flex flex-row mb-3 ml-3"
													>
														{this.cardTopCategory()}
													</div>
												</GlideComponent>
											</Colxx>
											<Colxx lg="6" md="12" className="mb-4">
												<Row>{this.cardActivitiesStatics()}</Row>
											</Colxx>
										</Row>
									</CardBody>
								</Card>
							</Colxx>
						</Row>

						<Row>
							<Colxx lg="12" md="12" className="mb-4">
								<Card className="dashboard-top-rated h-100 mb-4">
									<CardBody>
										<CardTitle>
											<p className="font-weight-bold"></p>
										</CardTitle>
										<Row>
											<Colxx lg="6" md="12" className="mb-4">
												<Row>{this.cardModel()}</Row>
											</Colxx>
											<Colxx lg="6" md="12" className="mb-4">
												<Row>{this.renderDatePicker()}</Row>
												<Row>{this.cardMessages()}</Row>
											</Colxx>
										</Row>
									</CardBody>
								</Card>
							</Colxx>
						</Row>

						{/* <Row>
							<Colxx xxs="12" className="mb-4">
								<AppVisitsChartCard data={datas} labels={labes} />
							</Colxx>
						</Row> */}
					</>
				)}
			</Fragment>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, null)(Dashboards);

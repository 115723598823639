import React from "react";
import IconCard from "./IconCard";
import GlideComponent from "./GlideComponent";

const IconCardsCarousel = ({ className = "icon-cards-row", data }) => {
	return (
		<div className={className}>
			<GlideComponent
				settings={{
					gap: 2,
					perView: 4,
					hideNav: true
				}}
				//className="d-flex flex-wrap bd-highlight "
			>
				{data.map((item, index) => {
					return (
						<div key={`icon_card_${index}`} className="p-2">
							<IconCard
								{...item}
								icon={item.icon}
								title={item.title}
								value={item.value}
							/>
						</div>
					);
				})}
			</GlideComponent>
		</div>
	);
};
export default IconCardsCarousel;

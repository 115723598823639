import React from "react";
import { Card, CardBody, CardTitle, CardHeader } from "reactstrap";
import { Colxx } from "components/CustomBootstrap";
import {
	buildStyles,
	CircularProgressbarWithChildren
} from "react-circular-progressbar";
const CarrdStatictics = ({ model, percent, pathColor, total = 0 }) => {
	return (
		<Colxx className=" mb-4 mr-1">
			<Card>
				<CardHeader className="p-0 position-relative">
					<div className="position-absolute handle card-icon">
						<i className="simple-icon-shuffle" />
					</div>
				</CardHeader>
				<CardBody className="d-flex justify-content-between align-items-center">
					<CardTitle className="mb-0 text-color-black ">
						{model.title}
						{": "}
						{percent ? `${model.count}` : ``}
					</CardTitle>
					<div className="progress-bar-circle text-center">
						<CircularProgressbarWithChildren
							value={
								percent ? Math.round((model.count * 100) / total) : model.count
							}
							strokeWidth={3}
							styles={buildStyles({
								pathColor: `${pathColor}`,
								trailColor: percent ? `#d6d6d6` : `${pathColor}`,
								backgroundColor: percent ? `#3e98c7` : `${pathColor}`
							})}
						>
							<div style={{ fontSize: 13 }}>
								<span>
									{percent
										? Math.round((model.count * 100) / total)
										: model.count}
									{percent ? `%` : ``}
								</span>
							</div>
						</CircularProgressbarWithChildren>
					</div>
				</CardBody>
			</Card>
		</Colxx>
	);
};
export default CarrdStatictics;

import {
	UPDATE_TOKEN,
	UPDATE_TOKEN_SUCCESS,
	UPDATE_TOKEN_FAILURE
} from "constants/actionTypes";

export const updateToken = profile => ({
	type: UPDATE_TOKEN,
	profile
});
export const updateTokenSuccess = profile => ({
	type: UPDATE_TOKEN_SUCCESS,
	profile
});
export const updateTokenFailure = profile => ({
	type: UPDATE_TOKEN_FAILURE,
	profile
});
export * from "./asyncActions";

import {
	insertNewCategory,
	insertNewAsset,
	uploadImage,
	deleteImage,
	UpdateAsset,
	UpdateCategoryData
} from "api/category";

import { registerCategorySuccess, updateSuccess } from "../actions";

export const handleAddNewcategoryAsync = (
	accessToken,
	categoryData,
	// assetData,
	imageFileData
) => async dispatch => {
	try {
		var response;
		let responseUploadImg = await uploadImage(accessToken, imageFileData);
		if (responseUploadImg) {
			let responseUpload = responseUploadImg["result"]["files"]["file"][0];
			let assetData = {
				name: responseUpload.name,
				type: responseUpload.type,
				container: responseUpload.container
			};
			let responseAsset = await insertNewAsset(accessToken, assetData);
			console.log("responseAsset ==> ", responseAsset);
			// assetId
			if (responseAsset) {
				var dataImg = categoryData;
				dataImg["coverId"] = responseAsset["assetId"];
				response = await insertNewCategory(accessToken, dataImg);
			}
			console.log("data ==> ", response);
		}
		dispatch(registerCategorySuccess(response));
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *
 * @param {*} accessToken
 * @param {*} categoruID
 * @param {*} categoryData
 * @param {*} imageFileData
 */

/**
 *
 * @param {Strin} accessToken
 * @param {String} assetId
 * @param {Object} newCategoryData
 * @param {String} categoryId
 * @param {String} oldImageName
 * @param {File} newImageFileData
 */
export const handleUpdateCategoryAsync = (
	accessToken,
	assetId,
	newCategoryData,
	categoryId,
	oldImageName,
	newImageFileData
) => async dispatch => {
	try {
		var response;
		/*** Upload new Data */
		response =
			newCategoryData && newCategoryData !== null
				? await UpdateCategoryData(accessToken, newCategoryData, categoryId)
				: null;
		/*** Upload new Image */
		let responseUploadImg =
			newImageFileData && newImageFileData !== null
				? await uploadImage(accessToken, newImageFileData)
				: null;
		if (responseUploadImg && responseUploadImg !== null) {
			let responseUpload = responseUploadImg["result"]["files"]["file"][0];
			let assetData = {
				name: responseUpload.name,
				type: responseUpload.type
			};
			let response = await UpdateAsset(accessToken, assetData, assetId);
			//let responseDeleteImage = await deleteImage(accessToken, oldImageName);
			console.log("responseAsset <----> ", response);
			//console.log("responseDeleteImage <---> ", responseDeleteImage);
			// assetId
			// if (responseUpdateAsset) {
			// 	// var dataImg = categoryData;
			// 	// dataImg["coverId"] = responseUpdateAsset["assetId"];
			// 	response = await deleteImage(accessToken, oldImageName);
			// }
			console.log("data ==> ", response);
		}

		dispatch(registerCategorySuccess(response));
		return response;
	} catch (error) {
		throw error;
	}
};

import React from "react";
import { Card, Badge } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import moment from "moment";
const badge = date => {
	if (date === moment()) {
		return (
			<Badge color={"primary"} pill>
				En cours
			</Badge>
		);
	} else if (date < moment()) {
		return (
			<Badge color={"secondary"} pill>
				Terminé
			</Badge>
		);
	} else {
		return (
			<Badge color={"light"} pill>
				En attente
			</Badge>
		);
	}
};

/** Show activity detail */
// const onClickDetail = element => {
// 	history.push({
// 		pathname: `${match.url}/activity`, // UI path
// 		state: { detail: element } // Pass the item to display
// 	});
// };
const ThumbListView = props => {
	const { data, collect } = props;
	const participants = data.Participants.filter(
		item => item.invitationStateId === 2
	);
	return (
		<ContextMenuTrigger id="menu_id" data={data.activityId} collect={collect}>
			<Card
				className={classnames("d-flex flex-row border-radius")}
				onClick={() => {
					console.log("onClick");
					props.history.push({
						pathname: `/admin/app/activity`, // UI path
						state: { detail: data } // Pass the item to display
					});
				}}
			>
				<NavLink to={`?p=${data.activityId}`} className="d-flex">
					{/* <img
							alt={"activity Image"}
							src={process.env.REACT_APP_URL_API + "/" + product.coverURL}
							className="list-thumbnail responsive border-0 card-img-left"
							style={{
								height: 100,
								width: 250
							}}
						/> */}
				</NavLink>
				<div className="pl-2 d-flex flex-grow-1 min-width-zero">
					<div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
						<p className="mb-1 text-muted text-semibold  w-sm-100 ">
							{data.Address.fullAddress}
						</p>
						<p className="mb-1 text-muted text-semibold w-sm-100">
							{data.isPublic === 1 ? "Public" : "Privée"}
						</p>
						<p className="mb-1 text-muted font-semibold  w-sm-100">
							{"Créé à " + moment(moment(data.createdAt)).format("DD/MM/YYYY")}
						</p>
						<p className="mb-1 text-muted text-semibold w-sm-100">
							{"commencer à " +
								moment(moment(data.startAt)).format("DD/MM/YYYY")}
						</p>
						<p className="mb-1 text-muted text-semibold w-sm-100">
							{data.Comments.length + " commentaires"}
						</p>
						<p className="mb-1 text-muted text-semibold w-sm-100">
							{participants.length + " Participants"}
						</p>
						<p className="mb-1 text-muted text-semibold w-sm-100">
							{data.Pictures
								? data.Pictures.length + " images publiées"
								: "0 image publiée"}
						</p>

						{/* <p className="mb-1 text-muted text-semibold w-15 w-sm-100">
							{"Description: " + data.description !== null
								? data.description
								: "----"}
						</p> */}

						{/* <div className="w-15 w-sm-100">
							{badge(moment(moment(data.startAt)).format("DD/MM/YYYY"))}
						</div> */}
					</div>
				</div>
			</Card>
		</ContextMenuTrigger>
	);
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ThumbListView);

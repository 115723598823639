import { urlApi } from "../constants/defaultValues";
const loginWithEmailAndPassword = async (
	email,
	password,
	profileType = "user"
) => {
	try {
		const response = await fetch(
			urlApi + `/${profileType}/login?include=User`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ email: email, password: password })
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		const data = await response.json();
		return data;
	} catch (err) {
		throw err;
	}
};

const logoutUserFromServer = async (accessToken, profileType = "Experts") => {
	try {
		const response = await fetch(urlApi + `/${profileType}/logout`, {
			method: "POST",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			}
		});
		if (!response.ok) {
			throw "ERROR";
		}
		return true;
	} catch (err) {
		throw err;
	}
};

const resetPassword = async (email, profileType = "Experts") => {
	try {
		const response = await fetch(urlApi + `/${profileType}/reset`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ email })
		});
		if (!response.ok) {
			throw await response.json();
		}
		return true;
	} catch (err) {
		throw err;
	}
};

const registerUser = async (userData, profileType = "Experts") => {
	try {
		let urlApiRegistreOrUpdateUser = urlApi + `/${profileType}`;
		let action = "POST";
		let headers = {
			"Content-Type": "application/json"
		};
		const response = await fetch(urlApiRegistreOrUpdateUser, {
			method: action,
			headers,
			body: JSON.stringify(userData)
		});
		if (!response.ok) {
			throw await response.json();
		}
		const user = await response.json();
		return user;
	} catch (err) {
		throw err;
	}
};

const setNewPassword = async (accessToken, newPassword, profileType) => {
	try {
		const response = await fetch(urlApi + `/${profileType}/reset-password`, {
			method: "POST",
			headers: {
				Authorization: accessToken,
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ newPassword })
		});
		if (!response.ok) {
			throw await response.json();
		}
		return true;
	} catch (err) {
		throw err;
	}
};
const confirmUserRegistration = async (user_id, accessToken, profileType) => {
	try {
		const response = await fetch(
			urlApi + `/${profileType}/confirm?uid=${user_id}&token=${accessToken}`
		);
		if (!response.ok) {
			throw await response.json();
		}
		return true;
	} catch (err) {
		throw err;
	}
};

/**
 *
 */
const checkIfUserIsExist = async (email, profileType) => {
	try {
		const response = await fetch(
			urlApi + `/${profileType}/checkIfUserExistByEmail`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ email })
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		let { exist } = await response.json();
		return exist;
	} catch (err) {
		throw err;
	}
};

/**
 * Request codeSMS validation
 */
const requestCodeValidation = async (email, phone, profileType) => {
	try {
		const response = await fetch(
			urlApi + `/${profileType}/requestValidatePhoneCode`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ email, phone })
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		let { sent } = await response.json();
		return sent;
	} catch (err) {
		throw err;
	}
};

/**
 * Request codeSMS validation
 */
const checkIsValidatePhoneCode = async (email, phone, code, profileType) => {
	try {
		const response = await fetch(
			urlApi + `/${profileType}/checkIsValidatePhoneCode`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ email, phone, code })
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		let { codeValid } = await response.json();
		return codeValid;
	} catch (err) {
		throw err;
	}
};

/**
 * Connexion utilisateurs avec un token
 */
const authenticateWithToken = async (token, profileType) => {
	try {
		const response = await fetch(
			urlApi + `/${profileType}/authenticateWithToken`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ token })
			}
		);
		if (!response.ok) {
			throw await response.json();
		}
		return await response.json();
	} catch (err) {
		throw err;
	}
};

export {
	loginWithEmailAndPassword,
	logoutUserFromServer,
	resetPassword,
	registerUser,
	setNewPassword,
	confirmUserRegistration,
	checkIfUserIsExist,
	requestCodeValidation,
	checkIsValidatePhoneCode,
	authenticateWithToken
};

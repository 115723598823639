import React, { Component, Fragment } from "react";
//UI components
import { Colxx, Separator } from "components/CustomBootstrap";
import BreadcrumbContainer from "components/BreadcrumbContainer";
import GlideComponentThumbs from "components/carousel/GlideComponentThumbs";
// import SingleLightbox from "components/pages/SingleLightbox";
import { Row, Card, CardBody, CardTitle, NavLink, Button } from "reactstrap";
//import { NavLink } from "react-router-dom";
// import Lightbox from "react-image-lightbox";
import Imagecard from "components/DashbordCard/ImageCard";
import * as moment from "moment";
import "moment/locale/fr";
import { connect } from "react-redux";
import logo from "assets/img/user-13-icon-256.png";
import EditActivityModal from "components/EditActivityModal";
class DetailActivityComponent extends Component {
	constructor(props) {
		super(props);
		this.onThumbClick = this.onThumbClick.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
		this.confirmUpdateActivity = this.confirmUpdateActivity.bind(this);
		this.setActivityAddress = this.setActivityAddress.bind(this);
		this.setActivityDate = this.setActivityDate.bind(this);
		this.onCancelAddress = this.onCancelAddress.bind(this);
		this.onCancelDate = this.onCancelDate.bind(this);
		const propsctivity = this.props.location.state.detail;
		this.state = {
			isLoading: true,
			currentActivity: null,
			isOpen: false,
			modalOpen: false,
			selectedAct: null,
			newActivityAssress: "",
			newActivityDate: ""
		};
	}
	/**
	 * Update category data
	 */
	confirmUpdateActivity = () => {
		const { id: accessToken } = this.props.currentUser;
		console.log("im here -> confirmUpdateActivity");
	};
	onCancelDate = event => {
		this.setState({
			newActivityDate: ""
		});
	};
	onCancelAddress = event => {
		this.setState({
			newActivityAssress: ""
		});
	};
	setActivityDate = event => {
		this.setState({
			newActivityDate: event.target.value
		});
	};
	setActivityAddress = suggestion => {
		//console.log("Address --> ", event.target.value);
		console.log("Address --> ", suggestion);
		this.setState({
			newActivityAssress: suggestion.description
		});
	};

	/**
	 * handle Submit
	 * @param {event} event
	 * @param {error} errors
	 * @param {object} values
	 */
	handleSubmitUpdate(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			//submit
			this.confirmUpdateActivity();
		}
	}
	/** The state of making the modal**/
	toggleModal = () => {
		this.setState(prevState => ({
			modalOpen: !prevState.modalOpen
		}));
	};

	/**
	 * Toggle switch the nav tab
	 * @param {String} tab index of tab
	 */
	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
	}
	onThumbClick() {
		this.setState({ isOpen: true });
	}
	renderCommentsComponent = () => {
		const currentActivity = this.props.location.state.detail;
		const comments = currentActivity.Comments;
		return (
			<Colxx sm="12" md="6" lg="6" xl="6">
				<Card className="mb-4" style={{ borderRadius: "0.75rem" }}>
					<CardBody>
						<CardTitle>
							<i className="simple-icon-bubble mr-1" />
							<span>{comments.length} commentaires</span>
						</CardTitle>
						{comments.map(comment => {
							let src =
								comment.Commentator.Avatar ||
								comment.Commentator.providerAvatarURL !== null
									? comment.Commentator.Avatar
										? process.env.REACT_APP_URL_API +
										  `/storage/${comment.Commentator.Avatar.container}/download/` +
										  comment.Commentator.Avatar.name
										: comment.Commentator.providerAvatarURL
									: logo;
							return (
								<div
									className={
										"d-flex flex-row mb-2 border-bottom justify-content-between "
									}
								>
									<NavLink to="#">
										<img
											src={src}
											alt={""}
											style={{ minWidth: 40, height: 40 }}
											className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center small"
										/>
									</NavLink>
									<div className="pl-0 ml-0 flex-grow-1">
										<NavLink to="#">
											<p className="font-weight-medium mb-0">
												{/* {comment.Commentator.firstName +
													" " +

													comment.Commentator.lastName} */}
												{comment.Commentator.firstName !== null
													? comment.Commentator.firstName
													: ""}{" "}
												{comment.Commentator.lastName !== null
													? comment.Commentator.lastName
													: ""}
											</p>
											<p className="text-muted mb-0 text-small">
												{}
												{moment(comment.commentedAt)
													.locale("Fr")
													.format("DD/MM/YYYY à HH:mm")}
											</p>
										</NavLink>
										<p className="mt-3">{comment.comment}</p>
									</div>
								</div>
							);
						})}
					</CardBody>
				</Card>
			</Colxx>
		);
	};
	renderParticipantsComponent = () => {
		const currentActivity = this.props.location.state.detail;
		const allParticipants = currentActivity.Participants;
		const participants = allParticipants.filter(
			el => el.invitationStateId === 2
		);
		return (
			<Colxx sm="12" md="6" lg="6" xl="6" className="center">
				<Card className="mb-4" style={{ borderRadius: "0.75rem" }}>
					<CardBody>
						<CardTitle>
							<i className="simple-icon-people mr-1" />
							<span>
								{participants.length}/{currentActivity.participantsNumber}{" "}
								participants
							</span>
						</CardTitle>
						<div className="remove-last-border remove-last-margin remove-last-padding">
							{participants.map(itemData => {
								let src =
									itemData.Invited.Avatar ||
									itemData.Invited.providerAvatarURL !== null
										? itemData.Invited.Avatar
											? process.env.REACT_APP_URL_API +
											  `/storage/${itemData.Invited.Avatar.container}/download/` +
											  itemData.Invited.Avatar.name
											: itemData.Invited.providerAvatarURL
										: logo;
								return (
									<div className="d-flex flex-row mb-3 pb-3 border-bottom justify-content-between align-items-center">
										<NavLink to="#" onClick={() => this.onThumbClick()}>
											<Imagecard
												alt={""}
												src={src}
												userCard={true}
												text={this.props.title}
												style={{ minWidth: 40, height: 40 }}
												className={`rounded img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall`}
											/>
										</NavLink>
										<div className="pl-0 flex-fill">
											<NavLink to="#">
												<p className="font-weight-medium mb-0">
													{itemData.Invited.firstName !== null
														? itemData.Invited.firstName
														: ""}{" "}
													{itemData.Invited.lastName !== null
														? itemData.Invited.lastName
														: ""}
												</p>
											</NavLink>
										</div>
									</div>
								);
							})}
						</div>
					</CardBody>
				</Card>
			</Colxx>
		);
	};
	renderImageComponent = () => {
		const currentActivity = this.props.location.state.detail;
		let detailImages = currentActivity.Pictures
			? currentActivity.Pictures.length > 0
				? currentActivity.Pictures
				: []
			: [];
		return (
			<Colxx sm="12" md="12" lg="12" xl="12" className="center">
				<Card className="mb-4" style={{ borderRadius: "0.75rem" }}>
					<CardBody>
						<CardTitle>
							<i className="simple-icon-picture mr-1" />
							<span>{detailImages.length} Image Publiées</span>
						</CardTitle>
						{currentActivity.Pictures ? (
							currentActivity.Pictures.length > 0 ? (
								<GlideComponentThumbs
									settingsImages={{
										bound: true,
										rewind: false,
										focusAt: 0,
										startAt: 0,
										gap: 5,
										perView: 1,
										data: detailImages
									}}
									settingsThumbs={{
										bound: true,
										rewind: false,
										focusAt: 0,
										startAt: 0,
										gap: 10,
										perView: 5,
										data: detailImages,
										breakpoints: {
											576: {
												perView: 4
											},
											420: {
												perView: 3
											}
										}
									}}
								/>
							) : null
						) : null}
					</CardBody>
				</Card>
			</Colxx>
		);
	};
	renderActivityInfos = () => {
		console.log("renderActivityInfos");
		const currentActivity = this.state.currentActivity;
		console.log("renderActivityInfos -> currentActivity ", currentActivity);
		const container = currentActivity.Category.Cover
			? currentActivity.Category.Cover.container
			: "";
		const imageName = currentActivity.Category.Cover
			? currentActivity.Category.Cover.name
			: "";
		return (
			<Colxx sm="12" md="6" lg="4" xl="4" className="mb-4">
				<Card className="mb-4" style={{ borderRadius: "0.75rem" }}>
					<div style={{ position: "relative" }}>
						<div className="position-absolute card-top-buttons">
							<Button
								outline
								color={"white"}
								className="icon-button"
								onClick={this.toggleModal}
							>
								<i className="simple-icon-pencil" />
							</Button>
						</div>
						<img
							style={{
								height: 250,
								borderTopLeftRadius: "0.75rem",
								borderTopRightRadius: "0.75rem"
							}}
							src={`${process.env.REACT_APP_URL_API}/storage/${container}/download/${imageName}`}
							alt="Detail"
							className="card-img-top"
						/>
						<div
							style={{
								position: "absolute",
								bottom: 5,
								right: 5,
								//background-color: black,
								color: "white",
								paddin: 10
							}}
						>
							<h4>{currentActivity.Category.name}</h4>
							<p>{currentActivity.isPublic ? "Public" : "Privé"}</p>
						</div>
					</div>

					<CardBody>
						<p className="text-muted text-small mb-2">Créateur:</p>
						<p>{`${
							currentActivity.Creator.firstName !== null
								? currentActivity.Creator.firstName
								: ""
						} ${
							currentActivity.Creator.lastName !== null
								? currentActivity.Creator.lastName
								: ""
						}`}</p>
						<p className="text-muted text-small mb-2">Date:</p>
						<div className="mb-3">
							{moment(currentActivity.startAt)
								.locale("Fr")
								.format("dddd DD/MM/YYYY")}{" "}
							à {currentActivity.atTime}
						</div>
						<p className="text-muted text-small mb-2">Adresse:</p>
						<p className="mb-3">{currentActivity.Address.fullAddress}</p>
						<p className="text-muted text-small mb-2">Partage de frais: </p>
						<p className="mb-3">
							{currentActivity.costShared ? currentActivity.costShared : "Non"}
						</p>
						<p className="text-muted text-small mb-2">Besoin de covoiturage:</p>
						<p className="mb-3">
							{currentActivity.carpoolNeeded
								? currentActivity.carpoolNeeded
								: "Non"}
						</p>
						<p className="text-muted text-small mb-2">Besoin de cagnotte: </p>
						<p className="mb-3">
							{currentActivity.potNeeded ? currentActivity.potNeeded : "Non"}
						</p>
						<p className="text-muted text-small mb-2">Description:</p>
						<p className="mb-3">
							{currentActivity.description !== null
								? currentActivity.description
								: "-"}
						</p>
					</CardBody>
				</Card>
			</Colxx>
		);
	};
	renderUI = () => {
		return (
			<>
				{this.renderActivityInfos()}
				<Colxx Colxx sm="12" md="10" lg="8" xl="8" className="mb-4">
					<Row>{this.renderImageComponent()}</Row>
					<Row>
						{this.renderParticipantsComponent()}
						{this.renderCommentsComponent()}
					</Row>
				</Colxx>
			</>
		);
	};
	componentDidMount() {
		setTimeout(() => {
			const currentActivity = this.props.location.state.detail;
			const isLoading = false;
			const newActivityAssress = currentActivity.Address.fullAddress;
			const newActivityDate = currentActivity.startAt;
			this.setState({
				currentActivity,
				isLoading,
				newActivityAssress,
				newActivityDate
			});
			console.log("currentActivity", currentActivity);
		}, 2000);
	}

	render() {
		const {
			isOpen,
			isLoading,
			modalOpen,
			currentActivity,
			newActivityAssress,
			newActivityDate
		} = this.state;
		return (
			<Fragment>
				<Row>
					<div className="disable-text-selection full-width">
						<Colxx xxs="12">
							<div className="mb-2 d-flex justify-content-between">
								<div>
									<BreadcrumbContainer
										heading={"Détails"}
										match={this.props.match}
									/>
								</div>
							</div>
							<Separator className="mb-5" />
						</Colxx>
					</div>
				</Row>

				<EditActivityModal
					toggleModal={this.toggleModal}
					newActivityAssress={newActivityAssress}
					newActivityDate={newActivityDate}
					handleSubmit={this.handleSubmitUpdate}
					modalOpen={modalOpen}
					setActivityAddress={this.setActivityAddress}
					setActivityDate={this.setActivityDate}
					onCancelAddress={this.onCancelAddress}
					onCancelDate={this.onCancelDate}
				/>
				<Row>{isLoading ? <div className="loading" /> : this.renderUI()}</Row>
				{/** Open participant image */}
				{/* {isOpen && (
					<Lightbox
						mainSrc={this.props.thumb}
						onCloseRequest={() => this.setState({ isOpen: false })}
						closeLabel="Annuler"
						imageCaption="testtttt"
					/>
				)} */}
			</Fragment>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	const { user } = auth;
	return { currentUser: user };
};

export default connect(mapStateToProps, null)(DetailActivityComponent);

import {
	LOGOUT_USER,
	LOGIN_USER_SUCCESS,
	REGISTER_USER,
	REGISTER_USER_SUCCESS,
	UPDATE_USER_SUCCESS,
	SET_USER_TEMP_DATA,
	EMPTY_USER_TEMP_DATA
} from "constants/actionTypes";

export const logoutUser = () => ({
	type: LOGOUT_USER
});
export const loginUserSuccess = user => ({
	type: LOGIN_USER_SUCCESS,
	user
});
export const registerUser = user => ({
	type: REGISTER_USER,
	user
});
export const registerUserSuccess = user => ({
	type: REGISTER_USER_SUCCESS,
	user
});
export const updateSuccess = user => ({
	type: UPDATE_USER_SUCCESS,
	user
});
export const updateUserTempData = tempUser => ({
	type: SET_USER_TEMP_DATA,
	tempUser
});
export const emptyUserTempData = () => ({
	type: EMPTY_USER_TEMP_DATA
});

export * from "./asyncActions";

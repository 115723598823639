import React from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardBody, CardTitle } from "reactstrap";
import { element } from "prop-types";
import Imagecard from "components/DashbordCard/ImageCard";
import logo from "assets/img/user-13-icon-256.png";
const details = data => {
	return data.map((element, index) => {
		return (
			<p key={"detail" + index} className="text-muted mt-1 mb-0 text-small">
				{element.fkBlockeduserUserrel.firstName}{" "}
				{element.fkBlockeduserUserrel.lastName}
			</p>
		);
	});
};
const CardUsersBlocked = ({ blockedUsers }) => {
	const d = blockedUsers.reduce(
		(
			catsSoFar,
			{
				userToBlockId = [],
				userId,
				fkBlockeduserUserrel,
				fkBlockeduserUsertoblockrel
			}
		) => {
			if (!catsSoFar[userToBlockId]) catsSoFar[userToBlockId] = [];
			catsSoFar[userToBlockId].push({
				userToBlockId,
				userId,
				fkBlockeduserUserrel,
				fkBlockeduserUsertoblockrel
			});
			return catsSoFar;
		},
		{}
	);

	return (
		<Card>
			<CardBody>
				<CardTitle>
					<h5 className="font-weight-bold">
						<p>Utilisateurs bloqués: {Object.values(d).length}</p>
					</h5>
				</CardTitle>
				<div className="dashboard-list-with-user mb-4">
					<PerfectScrollbar
						options={{ suppressScrollX: true, wheelPropagation: false }}
					>
						{Object.values(d).map((user, index) => {
							return (
								<div
									key={"user" + index}
									className="d-flex flex-row mb-3 pb-3 border-bottom"
								>
									<NavLink to="#">
										{/* <img
											src={
												user[0].fkBlockeduserUsertoblockrel.providerAvatarURL
											}
											alt={user[0].fkBlockeduserUsertoblockrel.firstName}
											className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
										/> */}
										<Imagecard
											alt={""}
											src={
												user[0].fkBlockeduserUsertoblockrel.Avatar ||
												user[0].fkBlockeduserUsertoblockrel.providerAvatarURL
													? user[0].fkBlockeduserUsertoblockrel.Avatar
														? process.env.REACT_APP_URL_API +
														  "/storage/profile/download/" +
														  user[0].fkBlockeduserUsertoblockrel.Avatar.name
														: user[0].fkBlockeduserUsertoblockrel
																.providerAvatarURL
													: logo
											}
											userCard={true}
											text={
												(user[0].fkBlockeduserUsertoblockrel.firstName != null
													? user[0].fkBlockeduserUsertoblockrel.firstName
													: "") +
												" " +
												(user[0].fkBlockeduserUsertoblockrel.lastName != null
													? user[0].fkBlockeduserUsertoblockrel.lastName
													: "")
											}
											//style={{ width: 40, height: 40 }}
											className={`img-thumbnail list-thumbnail align-self-center small rounded`}
										/>
									</NavLink>

									<div className="pl-3 pr-2">
										<NavLink to="#">
											<p className="font-weight-medium mb-0 font-weight-bold">
												{user[0].fkBlockeduserUsertoblockrel.firstName}{" "}
												{user[0].fkBlockeduserUsertoblockrel.lastName}:
												{user.length}
												{" bloques"}
											</p>
											{/* <p className="text-muted mt-1 mb-0 text-small">
												bloqué par:
											</p> */}
											{details(user)}
										</NavLink>
									</div>
								</div>
							);
						})}
					</PerfectScrollbar>
				</div>
			</CardBody>
		</Card>
	);
};
export default CardUsersBlocked;

import React from "react";
import { Card, CardBody, Row } from "reactstrap";
import { Colxx } from "components/CustomBootstrap";
import Icon from "components/Icon";
const IconCard = ({ className = "mb-4", icon, title, value }) => {
	return (
		<div className={`icon-row-item ${className}`}>
			<Card /*style={{ width: "100%" }}*/>
				<CardBody
					className="text-center"
					//className="text-center font-weight-semibold"
					style={{ color: "#47799a" }}
				>
					{/* <Row>
						<Colxx lg="12"> */}
					<i className={icon} color="#47799a" />
					<p className="card-text font-weight-semibold">{title + ": "}</p>
					<p className="lead text-center">{value}</p>

					{/* </Colxx>
					</Row> */}
					{/* <p className="text-center"></p> */}
				</CardBody>
			</Card>
		</div>
	);
};

export default IconCard;

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const SET_USER_TEMP_DATA = "SET_USER_TEMP_DATA";
export const EMPTY_USER_TEMP_DATA = "EMPTY_USER_TEMP_DATA";
/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
/* CATEGORY */
export const REGISTER_CATEGORY = "REGISTER_CATEGORY";
export const REGISTER_CATEGORY_SUCCESS = "REGISTER_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
/* PROFILE */
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_TOKEN_SUCCESS = "UPDATE_TOKEN_SUCCESS";
export const UPDATE_TOKEN_FAILURE = "UPDATE_TOKEN_FAILURE";

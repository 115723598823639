import { combineReducers } from "redux";
import auth from "./auth/reducer";
import menu from "./menu/reducer";
import category from "./category/reducer";
import profile from "./profile/reducer";

const rootReducer = combineReducers({
	menu,
	auth,
	category,
	profile
});

export default rootReducer;
